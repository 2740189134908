.youtube {
	width: 100%;

	.video-wrapper {
		position: relative;
		width: 100%;
		display: flex;
		justify-content: center;
		height: 0;
		padding-top: 56.25%;
	}

	.video {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
	}
}
