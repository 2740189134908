@import '@/assets/styles/fonts.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/var.scss';

.nav {
	position: relative;
	z-index: 11;
	height: 100%;
}

.toggle {
	display: flex;
	align-items: stretch;
	height: 100%;
	font-family: $fontFutura;
	font-weight: 600;
	font-size: 16px;
	line-height: 130%;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	color: $black-1;
}

.wrapper {
	position: fixed;
	top: 63px;
	left: 0;
	display: flex;
	height: calc(100vh - 63px);
	width: 100%;
	opacity: 0;
	pointer-events: none;
	transform: translateY(-20px);
	background-color: $gray-2;
	transition: opacity 0.2s, transform 0.3s;

	&.visible {
		opacity: 1;
		transform: translateY(0);

		.listWrapp {
			pointer-events: all;
		}
	}

	@include maxWidth($sDesktop) {
		top: 58px;
	}
}

.listWrapp {
	display: flex;
	width: 100%;
	height: 100%;
	position: relative;
}

.listScroll {
	overflow-y: auto;
	width: 216px;
	padding: 26px 0;
	@include scrollBar($gray-2);
}
