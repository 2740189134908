@import '@/assets/styles/var.scss';
@import '@/assets/styles/fonts.scss';
@import '@/assets/styles/mixins.scss';

@function calcSlideWidth($quantity, $gap) {
	@return calc((100% - #{$gap}px * #{$quantity - 1}) / #{$quantity});
}

.rowSlider {
	position: relative;
	width: 100%;

	.swiper-slide {
		width: auto;
		height: auto;
		box-sizing: border-box;

		&:not(:last-child) {
			margin-right: 32px;

			@include maxWidth($tablet + 1) {
				margin-right: 24px;
			}

			@include maxWidth($sMobile) {
				margin-right: 6px;
			}
		}

		&:last-child {
			margin-right: 0 !important;
		}
	}

	&.locked {
		.prev,
		.next {
			display: none;
		}
	}

	&.arrowsOutside {
		.btnWrap {
			height: 100%;

			&::after {
				content: '';
				display: block;
				width: 100%;
				padding-top: 150%;
			}
		}

		&.btn {
			@include maxWidth($lMobile) {
				width: 32px;
				pointer-events: none;

				&::before,
				&::after {
					pointer-events: all;
				}
			}

			@include maxWidth($sMobile) {
				width: 30px;
			}

			.prev {
				transform: translate(calc(-100% + 20px));
			}

			.next {
				transform: translate(calc(100% - 20px));
			}
		}
	}

	.arrowsInside {
		.prev {
			transform: translate(8px, 0);
		}

		.next {
			transform: translate(-8px, 0);
		}
	}

	.facesType {
		.swiper-slide {
			box-sizing: border-box;
			width: calc((100% - 32px * 5) / 6);
			height: auto;

			&:not(:last-child) {
				margin-right: 32px;
			}

			@include maxWidth($tablet) {
				width: calc((100% - 24px * 5) / 6);

				&:not(:last-child) {
					margin-right: 24px;
				}
			}

			@include maxWidth(1000px) {
				width: calc((100% - 24px * 4) / 5);
			}

			@include maxWidth($mTablet) {
				width: calc((100% - 24px * 3) / 4);
			}

			@include maxWidth($lMobile) {
				width: calc((100% - 20px * 2) / 3);

				&:not(:last-child) {
					margin-right: 20px;
				}
			}

			@include maxWidth($mTablet) {
				width: calc((100% - 20px) / 2);
			}
		}
	}

	&.narrowCollection {
		.swiper-slide {
			width: calcSlideWidth(6, 24);
			margin-right: 24px;

			@include maxWidth(1000px) {
				width: calcSlideWidth(5, 24);
			}

			@include maxWidth($mTablet) {
				width: calcSlideWidth(4, 24);
			}

			@include maxWidth($lMobile) {
				width: calcSlideWidth(3, 20);
				margin-right: 20px;
			}

			@include maxWidth($sMobile) {
				width: calcSlideWidth(2, 20);
			}
		}
	}

	&.looks {
		.swiper-slide {
			width: calcSlideWidth(3, 24);
			margin-right: 24px;

			@include maxWidth(1000px) {
				width: calcSlideWidth(4, 24);
			}

			@include maxWidth($lMobile) {
				width: calcSlideWidth(2, 20);
			}
		}
	}

	&.checkout {
		.swiper-slide {
			width: calcSlideWidth(3, 16);
			margin-right: 16px;

			@include maxWidth($mTablet) {
				width: calcSlideWidth(4, 24);
				margin-right: 24px;
			}

			@include maxWidth($lMobile) {
				width: calcSlideWidth(3, 16);
				margin-right: 16px;
			}
		}

		.btnWrap {
			overflow: visible;
			left: -15px;

			&.right {
				right: -15px;

				@include maxWidth($lMobile) {
					right: -9px;
				}
			}
		}
	}

	&.viewedProduct {
		.swiper-slide {
			width: calcSlideWidth(8, 32);
			margin-right: 32px;

			@include maxWidth($tablet + 1) {
				width: calcSlideWidth(6, 24);
				margin-right: 24px;
			}

			@include maxWidth($mTablet + 1) {
				width: calcSlideWidth(4, 24);
				margin-right: 24px;
			}

			@include maxWidth($lMobile) {
				width: calcSlideWidth(3, 16);
				margin-right: 16px;
			}
		}
	}

	&.ordersType {
		width: calcSlideWidth(5, 14);
		margin-right: 14px;

		@include maxWidth($tablet) {
			width: calcSlideWidth(4, 14);
		}

		@include maxWidth($mobile) {
			width: calcSlideWidth(4, 12);
			margin-right: 12px;
		}
	}

	.btnWrap {
		position: absolute;
		top: 0;
		left: 0;
		width: 24px;
		height: 24px;
		pointer-events: none;

		&.right {
			left: auto;
			right: 0;
		}
	}

	.btn {
		position: absolute;
		top: 0;
		z-index: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;
		width: 24px;
		height: 100%;
		overflow: hidden;
		background: 0 0;
		pointer-events: all;
		transition: background 0.25s ease;

		@include maxWidth($tablet) {
			width: 20px;
		}

		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			display: block;
			width: 100%;
			padding-top: 100%;
			border-radius: 50%;
			transform: translateY(-50%);
		}

		&::after {
			content: '';
			position: absolute;
			top: 50%;
			display: block;
			width: 8px;
			height: 8px;
			border-right: 1px solid $black-1;
			border-bottom: 1px solid $black-1;
			transition: border-color 0.25s ease;
		}

		@include noTouch {
			&.active {
				&:hover {
					&::before {
						background: $black-1;
					}

					&::after {
						border-color: $white;
					}
				}
			}
		}

		&.disabled {
			&::after {
				border-color: $gray-1;
			}
		}

		&.prev {
			left: 0;
			transform: translate(-50%, 0);

			&::after {
				transform: translate(2px, -50%) rotate(-225deg);
			}
		}

		&.next {
			right: 0;
			transform: translate(50%, 0);

			&::after {
				transform: translate(-2px, -50%) rotate(-45deg);
			}
		}
	}
}
