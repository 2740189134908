@import '@/assets/styles/var.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/fonts.scss';

.h1 {
	@include h1;
	font-weight: 500;
	color: $black-1;

	@include maxWidth($tablet) {
		@include h1-mobile;
	}
}

.h2 {
	@include h2;
	font-weight: 450;
	color: $black-1;

	@include maxWidth($mTablet + 1) {
		@include h2-mobile;
	}
}

.h3 {
	@include h3;
	font-weight: 450;
	color: $black-1;

	@include maxWidth($mTablet + 1) {
		@include h3-mobile;
	}
}

.h4 {
	@include h4;
	font-weight: 450;
	color: $black-1;

	@include maxWidth($mTablet + 1) {
		@include h4-mobile;
	}
}

.large {
	margin-bottom: 48px;
}

.medium {
	margin-bottom: 40px;
}

.small {
	margin-bottom: 32px;
}

.x-small {
	margin-bottom: 24px;
}

.xx-small {
	margin-bottom: 16px;
}

.none {
	margin-bottom: 0;
}

.center {
	text-align: center;
}

.left {
	text-align: left;
}

.right {
	text-align: right;
}

.invisible {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	border: 0;
	padding: 0;
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	overflow: hidden;
	pointer-events: none;
	white-space: nowrap;
}

.success {
	color: $green-1;

	&::before {
		content: '';
		display: inline-block;
		vertical-align: top;
		width: 40px;
		height: 14px;
		margin-right: 19px;
		background: 0 0;
		border-bottom: 4px solid currentColor;
		border-left: 4px solid currentColor;
		transform: translate(4px, 14px) rotate(-45deg);

		@include maxWidth($mTablet) {
			width: 34px;
			height: 12px;
			border-width: 3px;
			transform: translate(3px, 9px) rotate(-45deg);
		}
	}
}

.fail {
	position: relative;
	padding-left: 60px;
	color: $black-1;

	@include maxWidth($mTablet) {
		padding-left: 32px;
	}

	&::before,
	&::after {
		content: '';
		position: absolute;
		top: 30px;
		left: 4px;
		display: block;
		width: 40px;
		height: 4px;
		background-color: currentColor;

		@include maxWidth($mTablet + 1) {
			top: 17px;
			left: -2px;
			width: 30px;
			height: 3px;
		}
	}

	&::before {
		transform: rotate(45deg);
	}

	&::after {
		transform: rotate(-45deg);
	}
}
