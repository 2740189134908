@import '@/assets/styles/var.scss';
@import '@/assets/styles/fonts.scss';

.link {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	display: block;
	overflow: hidden;
}
