@import '@/assets/styles/var.scss';
@import '@/assets/styles/fonts.scss';

.country-select-item {
	list-style: none;

	button {
		padding: 6px 16px;
		font-size: 18px;
		color: $black-1;
		text-align: left;
		line-height: 1.2;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;

		p {
			display: flex;
			align-items: center;
		}

		.icon {
			display: block;
			width: 20px;
			height: 12px;
			background-size: cover;
			background-repeat: no-repeat;
			margin-right: 10px;
		}

		&:hover {
			background-color: $gray-1;
			border-radius: 18px;
		}
	}

	&.active {
		button {
			color: $green-1;
		}
	}

	@media screen and (max-width: $mTablet) {
		padding: 0 20px;

		button {
			padding: 10px 0;
			border-bottom: 1px dashed $gray-1;

			&:hover {
				background: none;
			}
		}
	}
}
