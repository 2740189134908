@import '@/assets/styles/var.scss';
@import '@/assets/styles/fonts.scss';
@import '@/assets/styles/mixins.scss';

.burger {
	display: flex;
	align-items: center;
	margin-right: 30px;
	height: 100%;
	cursor: pointer;
	font-size: 16px;
	font-family: $fontFutura;
	line-height: 130%;
	font-weight: 400;
	letter-spacing: 0.1em;

	&.open {
		.line {
			transition-delay: 0.24s;
			transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
			transform: translate3d(0, -6px, 0) rotate(-45deg);

			&::before {
				top: 0;
				transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.12s,
					transform 0.12s cubic-bezier(0.215, 0.61, 0.355, 1) 0.24s;
				transform: rotate(-90deg);
			}

			&::after {
				top: 0;
				transition: top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.12s,
					opacity 0.1s linear 0.12s;
				opacity: 0;
			}
		}
	}

	@include noTouch() {
		& > * {
			transition: opacity 0.15s linear;
		}
	}

	&:hover > * {
		opacity: 0.85;
	}

	@include maxWidth($tablet + 1) {
		margin-right: 0;
		padding: 0 18px;
		margin-left: -18px;
	}

	@include maxWidth($mTablet + 1) {
		padding: 0 11px;
		margin-left: -11px;
	}
}

.box {
	position: relative;
	display: inline-block;
	width: 19px;
	height: 14px;

	&:not(:last-child) {
		margin-right: 13px;
	}
}

.line {
	position: absolute;
	bottom: 1px;
	display: block;
	width: 100%;
	height: 2px;
	background-color: currentColor;
	transition: transform 0.12s 0.12s cubic-bezier(0.55, 0.055, 0.675, 0.19);

	@include maxWidth($sDesktop) {
		bottom: 0;
	}

	&::before,
	&::after {
		content: '';
		position: absolute;
		display: block;
		width: 100%;
		height: 100%;
		background-color: currentColor;
		transition-timing-function: ease;
		transition-duration: 0.15s;
		transition-property: transform;
		transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.12s,
			transform 0.12s cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}

	&::before {
		top: -6px;
	}

	&::after {
		top: -12px;
		transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.24s, opacity 0.12s linear;
	}
}
