.leaflet-marker-icon {
    border: none !important;
    background: none !important;
}

.tippy-arrow {
    border-top: 10px solid white;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
}

@keyframes inAnimation {
    0% {
        opacity: 0;
        max-height: 0;
    }
    60% {
        opacity: 1;
    }
    100% {
        max-height: 493px;
    }
}

@keyframes outAnimation {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
        opacity: 0;
    }
}
