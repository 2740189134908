@import '@/assets/styles/var.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/fonts.scss';

.main {
	display: grid;
	grid-gap: 48px 32px;
	grid-template-columns: repeat(2, minmax(0, 1fr));

	@include maxWidth($lMobile) {
		grid-template-columns: minmax(0, 1fr);
	}
}

.item {
	position: relative;

	&:only-child {
		grid-column: 1 / -1;

		--nav-picture-image-height: 50%;
		--nav-picture-content-pb: 48px;
		--nav-picture-title-size: 40px;
		--nav-picture-title-mb: 16px;
		@include maxWidth($lMobile) {
			--nav-picture-title-size: 32px;
		}
	}
}

.image {
	position: relative;
	width: 1264px;
	height: 506px;
	overflow: hidden;

	@include maxWidth($xmDesktop + 1) {
		width: 100%;
	}

	@include maxWidth($tablet + 1) {
		width: 960px;
		height: 384px;
	}

	@include maxWidth($tablet) {
		width: 100%;
	}

	@include maxWidth($mTablet + 1) {
		width: 704px;
		height: 282px;
	}

	@include maxWidth($mTablet) {
		width: 100%;
	}

	@include maxWidth($mobile + 1) {
		width: 100%;
		margin: auto;
		height: 110.5vw;
	}

	@include maxWidth(374px) {
		width: 90vw;
		margin: auto;
		height: 112vw;
	}

	&::after {
		content: '';
		display: inline-block;
		vertical-align: middle;
		width: 0;
		padding-top: var(--nav-picture-image-height, 75%);
	}

	& img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.content {
	margin-top: 24px;
	color: $black-1;
	max-width: 800px;
	margin-right: auto;
	margin-left: auto;

	.title {
		font-family: $fontFutura;
		font-size: 40px;
		line-height: 140%;
		font-weight: 450;
		text-align: center;
		color: $black-1;

		@include maxWidth($mTablet + 1) {
			font-size: 28px;
			line-height: 120%;
		}

		@include maxWidth($mobile + 1) {
			text-align: left;
		}
	}

	.desc {
		margin-top: 8px;
		font-size: 20px;
		font-weight: 400;
		line-height: 130%;
		text-align: center;
		color: $black-2;

		@include maxWidth($mTablet + 1) {
			font-size: 18px;
			line-height: 130%;
		}

		@include maxWidth($mobile + 1) {
			text-align: left;
		}
	}

	.button {
		margin-top: 24px;
		color: $black-1;
		border-color: $black-1;
		margin-left: auto;
		margin-right: auto;
		width: 313px;

		@include maxWidth($mobile + 1) {
			width: 100%;
		}
	}

	.h1-title {
		font-size: 40px;
		line-height: 140%;

		@include maxWidth($mTablet + 1) {
			font-size: 28px;
			line-height: 120%;
		}
	}
	.h2-title {
		font-size: 32px;
		line-height: 140%;

		@include maxWidth($mTablet + 1) {
			font-size: 24px;
			line-height: 120%;
		}
	}
	.h3-title {
		font-size: 28px;
		line-height: 140%;

		@include maxWidth($mTablet + 1) {
			font-size: 20px;
			line-height: 120%;
		}
	}
}

.h1-content {
	margin-top: 24px;
}
.h2-content {
	margin-top: 16px;
}
.h3-content {
	margin-top: 16px;
}
