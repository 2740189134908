.main {
	.swiper-pagination-bullet {
		width: 12px;
		height: 12px;
	}
}

.slide {
	position: relative;
}

.link {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	display: block;
	overflow: hidden;
}

.image {
	position: relative;
	width: 100%;
	height: auto;
	overflow: hidden;

	&::after {
		content: '';
		display: inline-block;
		vertical-align: middle;
		width: 0;
		padding-top: var(--gallery-image-height, 133.33%);
	}

	& img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
