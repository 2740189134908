@import '@/assets/styles/var.scss';
@import '@/assets/styles/fonts.scss';
@import '@/assets/styles/mixins.scss';

.wrapper {
	margin-bottom: 16px;
	display: flex;
	align-items: center;

	@include maxWidth($tablet + 1) {
		margin-bottom: 8px;
	}

	.check {
		display: flex;
		align-items: center;
		cursor: pointer;
		z-index: 100;
	}

	.checkDisabled {
		cursor: default !important;
	}

	.input {
		clip: rect(0 0 0 0);
		width: 1px;
		height: 1px;
		margin: -1px;
		position: absolute;
		appearance: none;
	}

	.box {
		position: relative;
		flex-shrink: 0;
		width: 20px;
		height: 20px;
		background-color: $white;
		border: 1px solid $black-4;
		border-radius: 26px;
		margin-right: 12px;
		transition: background-color 0.2s ease, border 0.2s ease;

		@media screen and (max-width: $mTablet) {
			margin-right: 6px;
		}

		&::before {
			content: '';
			position: absolute;
			top: 5px;
			left: 4px;
			display: block;
			width: 10px;
			height: 5px;
			border-left: 2px solid $white;
			border-bottom: 2px solid $white;
			opacity: 0;
			transform: scale(0.4) rotate(-45deg);
			transform-origin: center;
			transition: opacity 0s 0.25s, transform 0.25s ease-out;
		}

		&:hover {
			border: 1px solid $green-1;
		}

		&.isError {
			border: 1px solid $red-1;
		}
	}

	.boxDisabled {
		cursor: default !important;

		&:hover {
			border: 1px solid $gray-1;
		}
	}

	.label {
		@include content-book-18;
		text-align: left;
		color: $black-1;
		display: flex;
		align-items: center;
		width: 100%;
	}

	.disabled {
		color: $black-4;
		cursor: default;
	}

	.input:focus {
		+ .box {
			border: 1px solid $green-1;
		}
	}

	.input:checked {
		+ .box {
			background-color: $green-1;
			border: 1px solid $green-1;

			&::before {
				transform: scale(1) rotate(-45deg);
				opacity: 1;
				transition: transform 0.25s ease-out;
			}
		}
	}

	.input:disabled {
		+ .box {
			cursor: not-allowed;
		}
	}
}
