@import '@/assets/styles/mixins.scss';

.offset {
	height: 32px;

	@include maxWidth($tablet) {
		height: 0;
	}

	@include maxWidth($mobile) {
		height: 16px;
	}
}
