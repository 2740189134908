@import '@/assets/styles/fonts.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/var.scss';

.footer {
	padding: 0 0 16px;
	background: $white;
	position: relative;
	flex: 0 0 auto;
	content-visibility: auto;
}

.new-year-bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 470px;
	background: url('/images/svg/pattern-the-winter-snow.svg') 50% -100px no-repeat;
}

.container {
	display: grid;
	padding-top: 48px;
	border-top: 1px solid $gray-1;
	grid-template-columns: repeat(3, minmax(0, 1fr)) 292px;
	grid-gap: 42px 32px;
	min-width: 280px;
	width: $containerDefault;
	max-width: $maxContentWidth;
	margin: 0 auto;
	grid-template-areas:
		'list-1 list-2 list-3 info'
		'div-1 div-1 div-1 div-1'
		'features features payment payment'
		'div-2 div-2 div-2 div-2'
		'legal legal legal legal';
	position: relative;
	z-index: 1;

	@include maxWidth($tablet + 1) {
		grid-template-columns: repeat(3, minmax(0, 1fr)) 222px;
		grid-gap: 32px 24px;
		width: 93.75%;
	}

	@include maxWidth($mTablet + 1) {
		width: 91.66%;
	}

	@include maxWidth($lMobile) {
		width: 89.3%;
	}
	@include maxWidth($mobile + 1) {
		padding-top: 0;
	}

	@include maxWidth($tablet + 1) {
		grid-template-columns: repeat(3, minmax(0, 1fr)) 222px;
		grid-template-areas:
			'list-1 list-2 list-3 list-3'
			'div-1 div-1 div-1'
			'info payment'
			'div-2 div-2 div-2'
			'features features features'
			'legal legal legal';
	}

	@include maxWidth(940px) {
		grid-template-columns: repeat(2, minmax(0, 1fr)) 222px;
		grid-template-areas:
			'list-1 list-2 list-3'
			'div-1 div-1 div-1'
			'info info info'
			'div-2 div-2 div-2'
			'features features features'
			'payment payment payment'
			'legal legal legal';
		grid-gap: 32px;
	}

	@include maxWidth(760px) {
		grid-template-columns: minmax(0, 1fr);
		grid-template-areas:
			'list-1'
			'list-2'
			'list-3'
			'div-1'
			'location'
			'info'
			'div-2'
			'features'
			'payment'
			'legal';
		grid-gap: 32px;
	}
}

.links {
	width: 292px;

	@include maxWidth(1270px) {
		width: 230px;
	}

	@include maxWidth($tablet + 1) {
		width: 222px;
	}

	@include maxWidth($mobile + 1) {
		width: 100%;
	}

	&.order-1 {
		grid-area: list-1;
	}

	&.order-2 {
		grid-area: list-2;
	}

	&.order-3 {
		grid-area: list-3;
	}

	&.order-5 {
		grid-area: location;
	}

	&.order-1.desktopLinks {
		display: flex;
		flex-direction: column;

		@include maxWidth($mobile + 1) {
			display: none;
		}
	}

	.content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex-grow: 1;
	}

	.location {
		position: relative;
		display: flex;
		gap: 10px;
		cursor: pointer;

		.locationTitle {
			background-image: linear-gradient(#bbbbbb, #bbbbbb);
			background-size: 100% 0px;
			background-position: 0px calc(100% - 1px);
			background-repeat: no-repeat;

			&:hover {
				background-size: 100% 1px;
			}
		}

		span {
			display: block;
			line-height: 120%;
		}

		.controlDropdown {
			position: absolute;
			top: calc(100% + 5px);
			left: 0;
			background: 0 0;
			cursor: default;
			opacity: 0;
			z-index: 15;
			visibility: hidden;
			pointer-events: none;
			transition: all 0.2s ease-out;

			&.active {
				top: calc(100% - 6px);
				opacity: 1;
				visibility: visible;
				pointer-events: all;
			}
		}

		.controlInner {
			position: relative;
			width: 302px;
			padding: 24px 32px;
			color: $black-1;
			background: $white;
			border-radius: 18px;
			box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
			transform: translateY(20px);
			transition: transform 0.3s ease-out;

			&::before {
				content: '';
				position: absolute;
				bottom: 100%;
				left: 58px;
				border-bottom: 9px solid $white;
				border-right: 10px solid transparent;
				border-left: 10px solid transparent;
			}
		}
	}
}

.title {
	@include footer-menu;
	color: $black-1;
	margin-bottom: 24px;

	@include maxWidth($sDesktop) {
		margin-bottom: 15px;
	}
}

.question {
	margin-bottom: 24px;
	font-size: 14px;
	color: $black-3;

	@include maxWidth($mobile + 1) {
		font-size: 16px;
	}
}

.btns {
	display: flex;
	justify-content: space-between;

	.btn {
		font-size: 16px;
		color: $black-3;
		background-image: linear-gradient($black, $black);
		background-size: 100% 0px;
		background-position: 0px calc(100% - 1px);
		background-repeat: no-repeat;
		cursor: pointer;

		&.active {
			color: $black;
			background-size: 100% 1px;
		}
	}
}

.info {
	grid-area: info;
	max-width: 296px;

	@include maxWidth($tablet + 1) {
		max-width: 222px;
	}

	@include maxWidth($mTablet + 1) {
		margin-top: 16px;
		max-width: 100%;
	}

	.work {
		margin-bottom: 32px;

		.phone {
			display: inline-flex;
			align-items: center;
			font-weight: 450;
			@include footer-menu;
			color: $black-1;
			border-bottom: 1px solid transparent;
			transition: color 0.23s linear;

			svg {
				margin-right: 7px;
			}

			&:hover {
				color: $black-2;
			}
		}

		.work-time {
			@include card-cart-block;
			font-weight: 400;
			color: $black-3;
			letter-spacing: 0.005em;
			white-space: nowrap;
		}
	}
}

.divider {
	grid-column: 1 / -1;
	height: 1px;
	background: $gray-1;

	&.first {
		grid-area: div-1;

		@include maxWidth($mTablet) {
			display: none;
		}
	}

	&.second {
		grid-area: div-2;
	}
}

.features {
	grid-area: features;

	@include maxWidth($tablet) {
		padding-bottom: 16px;
	}

	@include maxWidth($mTablet + 1) {
		padding-bottom: 0;
	}

	&-text {
		width: 100%;
		margin-bottom: 32px;

		@include maxWidth($mTablet + 1) {
			margin-bottom: 0;
		}

		p {
			color: $black-3;

			> span {
				display: inline-block;
			}
		}

		a {
			display: inline-block;
			vertical-align: baseline;

			> span {
				border-bottom: 1px solid transparent;
				transition: border-color 0.2s ease;
			}

			&:hover {
				> span {
					border-bottom-color: currentColor;
				}
			}
		}
	}
}

.feature {
	display: flex;
	// max-width: 180px;
	font-size: 16px;
	line-height: 130%;
	color: $black-1;

	svg {
		flex-shrink: 0;
		width: 32px;
		height: 32px;
		margin-right: 8px;
	}
}

.payment {
	grid-area: payment;

	@include maxWidth($mTablet + 1) {
		border-bottom: 1px solid $gray-1;
	}

	&List {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin-right: -16px;

		&Item {
			flex-shrink: 0;
			padding: 6px 0;

			&:not(:last-child) {
				margin-right: 16px;
			}

			.paymentImg {
				max-width: 66px;
				height: auto;
			}

			@include maxWidth($mTablet) {
				&:not(:last-child) {
					margin-right: 37px;
				}

				&:nth-child(5n) {
					margin-right: 0;
				}
			}
		}

		.bePaid {
			padding-top: 0;
		}

		@include maxWidth($sDesktop) {
			margin-right: 0;
		}

		@include maxWidth($mTablet + 1) {
			max-width: 85%;
			justify-content: space-between;
			padding-bottom: 32px;
		}

		@include maxWidth($mobile + 1) {
			justify-content: start;
			max-width: 100%;
		}
	}
}

.app {
	grid-area: app;

	.title {
		margin-bottom: 24px;
	}

	&-content {
		display: flex;
		align-items: center;
	}

	img {
		width: 85px;
		height: 118px;

		@include maxWidth($tablet + 1) {
			width: 82px;
			height: 114px;
		}
	}

	&Links {
		display: flex;
	}

	&-link {
		display: flex;
		align-items: center;
		width: 119px;
		height: 41px;
		padding-left: 12px;
		font-family: $fontFutura;
		font-weight: 450;
		font-size: 11.7324px;
		line-height: 120%;
		color: #fff;
		border-radius: 12px;
		background: $black-1;
		transition: background 0.23s ease;

		svg {
			width: 18px;
			margin-right: 11px;
			height: auto;
		}

		&:not(:last-child) {
			margin-right: 12px;
		}

		&:hover {
			background: $black-2;
		}

		@include maxWidth($tablet + 1) {
			width: 105px;
			height: 36px;
			font-size: 9px;
		}
	}
}

.legal {
	grid-area: legal;
	display: flex;
	justify-content: space-between;
	color: $black-4;
	font-family: $fontFuturaBook;

	@include maxWidth($tablet) {
		flex-direction: column;
	}
}

.copy {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	font-size: 14px;
	line-height: 130%;
	min-width: 184px;

	a {
		font-size: 12px;

		> span {
			display: inline-block;
			border-bottom: 1px solid transparent;
			transition: border-color 0.2s ease;

			@include maxWidth($tablet) {
				margin-top: 4px;
			}
		}

		&:hover {
			> span {
				border-bottom-color: currentColor;
			}
		}
	}

	@include maxWidth($tablet) {
		margin-bottom: 12px;
	}
}

.copyText {
	font-size: 12px;
	line-height: 130%;
	font-family: $fontFuturaBook;

	@include maxWidth($mTablet + 1) {
		max-width: 100%;
		margin-bottom: 32px;
	}
}

.dev {
	text-align: right;
	font-size: 14px;
	line-height: 130%;
	letter-spacing: 0.005em;
	max-width: 292px;

	@include maxWidth($sDesktop) {
		max-width: 180px;
	}

	@include maxWidth($tablet) {
		text-align: left;
		max-width: 100%;
	}

	a {
		display: inline-block;
		margin-left: 3px;

		& > span {
			border-bottom: 1px solid transparent;
			transition: border-color 0.2s ease;
		}

		&:hover {
			> span {
				border-bottom-color: currentColor;
			}
		}
	}
}

.text {
	color: $black-2;
	font-size: 14px;
	line-height: 130%;

	@include maxWidth($mTablet + 1) {
		font-size: 16px;
	}

	@include maxWidth($mobile + 1) {
		font-size: 14px;
	}

	span {
		display: inline;
	}
}

.workAndSubscribe {
	@include maxWidth($mTablet + 1) {
		display: flex;

		.work {
			margin-right: 104px;
		}
	}

	@include maxWidth($mobile + 1) {
		display: block;
	}
}

.socialList {
	@include maxWidth($mTablet + 1) {
		max-width: 222px;
	}
}

.desktopLinks {
	@include maxWidth($mobile + 1) {
		display: none;
	}
}

.mobileLinks {
	display: none;

	@include maxWidth($mobile + 1) {
		display: block;
	}

	&.order-2 {
		margin-top: -32px;
	}

	&.order-3 {
		margin-top: -32px;
	}

	&.order-5 {
		margin-top: -64px;
	}

	.title {
		margin-bottom: 0;

		.mobileLocationTitle {
			display: flex;
			align-items: center;
			gap: 10px;
		}
	}

	.modileLocationContent {
		width: 238px;
	}
}
