@import '@/assets/styles/var.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/fonts.scss';

.radio {
	display: block;
	cursor: pointer;
}

.title {
	position: relative;
	display: block;
	padding-top: var(--choice-pt, 4px);
	padding-bottom: var(--choice-pb, 4px);
	padding-right: var(--choice-pr, 0);
	padding-left: var(--choice-pl, 28px);
	font-family: var(--choice-font-family, inherit);
	font-weight: var(--choice-font-weight, inherit);
	font-size: var(--choice-font-size, 18px);
	letter-spacing: var(--choice-letter-space, initial);
	text-transform: var(--choice-text-transform, none);
	color: var(--choice-color, $black-2);
	user-select: none;

	&::before,
	&::after {
		content: var(--choice-frame-content, '');
		position: absolute;
		top: var(--choice-frame-top, 6px);
		right: var(--choice-frame-right, auto);
		bottom: var(--choice-frame-bottom, auto);
		left: var(--choice-frame-left, 0);
		display: block;
		width: var(--choice-frame-size, 20px);
		height: var(--choice-frame-size, 20px);
		margin-top: 3px;
	}

	&::before {
		background-color: var(--choice-frame-bg-color, #fff);
		border: 1px solid $black-4;
		transition: background-color 0.2s ease, border-color 0.2s ease;
	}

	&::after {
		opacity: 0;
		transform: scale(0.4);
		transform-origin: center;
		transition: opacity 0s 0.25s, transform 0.25s ease-out;
		top: var(--choice-circle-top, 12px);
		left: var(--choice-circle-left, 6px);
		background-image: none;
		background-color: #fff;
		width: 8px;
		height: 8px;
	}

	&::before,
	&::after {
		border-radius: var(--choice-frame-radius, 50%);
	}
}

.input {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	border: 0;
	padding: 0;
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	overflow: hidden;
	pointer-events: none;
	white-space: nowrap;
}

.checked {
	color: var(--choice-checked-color, $black-2);

	&::before {
		background-color: $green-1;
		border-color: $green-1;
	}

	&::after {
		transform: scale(1);
		opacity: 1;
		transition: opacity 0s, transform 0.25s ease-out;
	}
}

.focus {
	&:focus {
		&::before {
			box-shadow: 0 0 2px 0px $black-4;
		}
	}
}

.disabled {
	cursor: default;
	opacity: 0.5;
}

.disabledChecked {
	&::before {
		opacity: var(--choice-frame-disabled-opacity, 0.3);
	}
}

.notDisabledAndChecked {
	@include noTouch() {
		&:hover {
			&::before {
				border-color: $black-4;
			}
		}
	}
}
