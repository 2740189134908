@import '@/assets/styles/var.scss';
@import '@/assets/styles/fonts.scss';
@import '@/assets/styles/mixins.scss';

.phone-form {
	padding: 0 20px;
	margin-bottom: 104px;

	.form-control-wrapper {
		margin-bottom: 32px;

		&.withError {
			margin-bottom: 24px;
		}
	}

	.label {
		padding: 0;

		@include maxWidth($xsMobile) {
			margin-left: -8px;
		}
	}

	.form-control {
		display: grid;
		grid-template-columns: 88px minmax(0, 1fr);
		grid-gap: 16px;
		padding-top: 8px;

		.errorText {
			@include maxWidth($sMobile) {
				margin-left: -14px;
			}
		}
	}

	.agree-txt {
		color: $black-3;
		letter-spacing: 0.005em;
		@include footer-menu-2();

		a {
			color: $green-2;

			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}
	}

	.submit-btn {
		min-width: 100%;
		margin-top: 8px;

		@include maxWidth($tablet + 1) {
			margin-top: 16px;
		}
	}

	@media screen and (max-width: $mTablet) {
		padding: 0;

		.agree-txt {
			font-size: 15px;
		}
	}
}
