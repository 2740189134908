@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/var.scss';

.search {
	height: 100%;
	display: flex;
	align-items: center;
	transition: width 0.2s ease;

	> div {
		width: 100%;
		padding-right: 8px;
	}

	&.show {
		width: 215px;
	}

	.showSearch {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		color: $black-1;
		padding: 8px;
		height: 100%;

		span {
			margin-top: 5px;
		}

		svg {
			width: 16px;
			height: 16px;
		}

		.text {
			@include maxWidth($lMobile) {
				display: none;
			}
		}

		&.hidden {
			display: none;
		}
	}
}

.desktop {
	@include maxWidth($sDesktop) {
		display: none;
	}
}

.mobile,
.searchFieldMobile {
	display: none;

	@include maxWidth($sDesktop) {
		display: block;
	}
}

.greenText {
	color: $green-4;
}
