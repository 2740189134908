@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/var.scss';

.outer-container {
	position: relative;

	@include minWidth($desktop + 1) {
		max-width: $desktop;
		margin: 0 auto;
	}
}

.image {
	position: relative;

	&::after {
		content: '';
		display: inline-block;
		vertical-align: middle;
		width: 0;
		padding-top: 30%;
	}

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
