@import '@/assets/styles/var.scss';
@import '@/assets/styles/fonts.scss';
@import '@/assets/styles/mixins.scss';

@keyframes spinner {
	from {
		transform: rotate(0);
	}

	to {
		transform: rotate(359deg);
	}
}

.button {
	@include button-medium;
	height: 44px;
	padding: 11px 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
	border: 1px solid $green-1;
	position: relative;
	min-width: 220px;

	&.primary {
		background-color: $green-1;
		border-color: $green-1;
		color: $white;

		&:hover {
			border: none;
			background-color: $green-4;
		}

		&.disabled {
			border-color: $black-4;
			background-color: $black-4;
		}
	}

	&.primaryBlack {
		background-color: $black-1;
		border-color: $black-1;
		color: $white;

		&:hover {
			background-color: $black-2;
			border-color: $black-2;
		}

		&.disabled {
			color: $white;
			border-color: $black-4;
			background-color: $black-4;
		}
	}

	&.default {
		color: $green-4;
		border-color: $green-4;

		&:hover {
			border: none;
			background: $green-1;
			color: $white;
		}

		&.disabled {
			color: $black-3;
			border-color: $black-3;
			background-color: $white;
		}
	}

	&.defaultBlack {
		color: $black-1;
		border-color: $black-1;

		&:hover {
			border: none;
			color: $white;
			background-color: $black-1;
		}

		&.disabled {
			color: $black-3;
			border-color: $black-3;
			background-color: $white;
		}
	}

	&.link {
		@include card-text;
		color: $black-3;
		padding: 0px;
		height: auto;
		border: none;
		border-radius: 0;
		padding-bottom: 2px;
		min-width: auto;

		&.error {
			border-bottom: 1px solid $red-1;
			color: $red-1;
		}

		.buttonContent {
			border-bottom: 1px solid $black-3;
		}

		&:hover {
			color: $green-4;

			.buttonContent {
				border-bottom: 1px solid $green-4;
			}
		}
	}

	&.loading {
		&::before {
			content: '';
			position: absolute;
			left: calc(50% - 15px);
			top: calc(50% - 15px);
			transform: translate(-50%, -50%);
			transform-origin: center;
			z-index: 3;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			background-image: radial-gradient(circle, $green-1 13px, rgba(255, 255, 255, 0) 14px),
				linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
			background-repeat: no-repeat;
			background-position: 50% 50%;
			background-size: 100%;
			animation: spinner 0.6s infinite linear;
		}

		.buttonContent {
			opacity: 0;
		}
	}

	&.disabled {
		cursor: default;

		&.loading {
			&::before {
				background-image: radial-gradient(circle, $gray-1 13px, rgba(255, 255, 255, 0) 14px),
					linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
			}
		}
	}

	@include maxWidth($lMobile) {
		font-size: 18px;
		min-width: 170px;
	}
}
