@import '@/assets/styles/fonts.scss';
@import '@/assets/styles/var.scss';

.list {
	margin-left: -4px;
}

.item {
	&:not(:last-child) {
		margin-bottom: 8px;
	}
}

.link {
	display: block;
	@include footer-menu-2;
	letter-spacing: 0.005em;
	padding: 0 4px;
	color: $black-1;

	span {
		background-image: linear-gradient($black-4, $black-4);
		background-size: 100% 0px;
		background-position: 0px calc(100% - 1px);
		background-repeat: no-repeat;
	}

	&:hover:not(:active) span {
		background-size: 100% 1px;
	}
}
