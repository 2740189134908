@import '@/assets/styles/var.scss';
@import '@/assets/styles/fonts.scss';

.country-select {
	position: relative;
	z-index: 101;

	.country-select-trigger {
		border: 1px solid $gray-1 !important;
		padding: 0;
		transition: border 300ms ease-out;
		min-width: 100%;

		> span {
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 4px;
			overflow: hidden;
		}

		&:hover,
		&:focus {
			border: 1px solid $green-2 !important;
		}

		&::after {
			content: '';
			width: 16px;
			height: 16px;
			margin-left: 10px;
			background-size: 100% 100%;
			background-repeat: no-repeat;
			transition: transform 0.25s ease-in-out;
			background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.604 5l-5.657 5.657.707.707 5.657-5.657L13.604 5z' fill='%23292C33'/%3E%3Cpath d='M3.707 5l5.657 5.657-.707.707L3 5.707 3.707 5z' fill='%23292C33'/%3E%3C/svg%3E");
		}

		&.active {
			border-bottom: 1px solid transparent;

			&::after {
				transform: rotateX(180deg);
			}
		}
	}
}
