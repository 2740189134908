@import '@/assets/styles/fonts.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/var.scss';

.list {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	flex-shrink: 0;
	justify-content: space-between;

	&-item {
		&:not(:first-child) {
			margin-left: 16px;
		}

		a {
			display: block;
			transition: opacity 0.25s ease;
		}

		.icon {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	&.messengers {
		margin-bottom: 32px;

		.list-item {
			.icon {
				width: 22px;
				height: 22px;
			}
		}
	}

	&.socials {
		.list-item {
			.icon {
				width: 22px;
				height: 22px;
			}
		}
	}

	&.top {
		margin-bottom: 24px;
		justify-content: flex-start;

		.list-item {
			.icon {
				width: 32px;
				height: 32px;
				border-radius: 50%;
			}
		}

		@include maxWidth($tablet) {
			margin-bottom: 32px;
		}
	}
}
