@import '@/assets/styles/var.scss';
@import '@/assets/styles/fonts.scss';
@import '@/assets/styles/mixins.scss';

.section {
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	flex-wrap: wrap;
	flex-grow: 1;
	text-align: center;
	margin-top: 40px;
	margin-bottom: 40px;

	@include maxWidth($lMobile) {
		margin-top: 32px;
		margin-bottom: 32px;
	}

	.container {
		max-width: 400px;
	}
}
