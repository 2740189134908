@import '@/assets/styles/var.scss';
@import '@/assets/styles/mixins.scss';

.wrapp {
	height: 504px;
	display: flex;
	padding: 64px 20px 20px;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;

	@include maxWidth($lMobile) {
		height: 491px;
	}
}

.image {
	height: 92px;
	width: 146px;
	margin-bottom: 32px;
}

.title {
	margin-bottom: 12px;
	font-size: 32px;
	align-self: center;
	color: $black-1;

	@include maxWidth($lMobile) {
		font-size: 24px;
	}
}

.text {
	margin-bottom: 48px;
	font-size: 18px;
	align-self: center;
	text-align: center;

	@include maxWidth($lMobile) {
		font-size: 16px;
	}
}

.btnContainer {
	display: flex;
	align-content: center;
	justify-content: center;

	@include maxWidth($lMobile) {
		width: 100%;
	}

	.btn {
		width: 300px;

		@include maxWidth($lMobile) {
			width: 100%;
		}
	}
}
