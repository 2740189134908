@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/var.scss';

.inner-container {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	height: 100%;
	padding: 40px 0;

	@include maxWidth($tablet) {
		padding: 32px 0;
	}

	@include maxWidth($mTablet - 1) {
		position: static;
		padding: 21px 0 0;
	}
}

.content {
	flex-shrink: 0;
	width: 100%;
	max-width: 510px;
	max-height: 100%;
	padding: 21px 28px 32px 24px;
	margin-top: auto;
	font-size: 18px;
	text-align: left;
	background: #fff;

	@include maxWidth($mTablet - 1) {
		padding: 0;
	}
}

.title {
	display: block;
	font-weight: 500;
	font-size: 32px;
	color: $black-1;

	&:not(:last-child) {
		margin-bottom: 16px;
	}
}

.description {
	&:not(:first-child) {
		margin-top: -8px;
	}

	&:not(:last-child) {
		margin-bottom: 16px;
	}
}

.text {
	display: inline;
	padding: 1px 0;
	color: $black-1;
	background-image: linear-gradient(180deg, $black-4, $black-4);
	background-position: 0 100%;
	background-repeat: no-repeat;
	background-size: 100% 1px;
}
