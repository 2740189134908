// sizes
$xsMobile: 324px;
$slMobile: 390px;
$sMobile: 429px;
$mobile: 499px;
$lMobile: 650px;
$mTablet: 767px;
$tablet: 1023px;
$sDesktop: 1279px;
$xmDesktop: 1365px;
$mDesktop: 1439px;
$desktop: 1919px;

//fonts
$fontFutura: 'Futura PT', Arial, sans-serif;
$fontFuturaBook: 'Futura PT Book', Arial, sans-serif;
$fontFuturaDemi: 'Futura PT Demi', Arial, sans-serif;

$maxContentWidth: 1264px;

//containers
$containerDefault: 92.53%;
$containerTablet: 93.75%;
$containerMTablet: 91.66%;
$containerLMobile: 89.3%;

//colors
$black-1: #171717;
$black-2: #585858;
$black-3: #959595;
$black-4: #bbbbbb;
$black-5: #74849c;
$black-6: #494b52;

$green-1: #0fb769;
$green-2: #59896d;
$green-3: #d4dccd;
$green-4: #0d9a58;
$green-5: #bfe6d4;
$green-6: #1a4e2d;

$red-1: #ea5656;
$red-2: #ff7878;
$red-3: #fde5e5;
$red-4: #f21818;

$blue-1: #74849c;
$blue-2: #1232a1;
$blue-3: #cdd6f9;
$blue-4: #dceaff;
$blue-5: #1a2f79;

$gray-1: #f0f0f0;
$gray-2: #f8f8f8;

$yellow-1: #f6980e;
$yellow-2: #f9f5d0;

$white: #fff;
$black: #000;
