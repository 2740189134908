@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/var.scss';
@import '@/assets/styles/fonts.scss';

.textVariants {
	display: flex;
	overflow: hidden;
	padding-bottom: 20px;

	@include maxWidth($tablet) {
		display: none;
	}

	&.hidden {
		opacity: 0;
	}

	.item {
		display: flex;
		flex-shrink: 0;
		justify-content: center;
		align-items: center;
		margin-right: 12px;
		color: $black-3;

		span {
			display: block;
		}

		.rest {
			font-size: 16px;
			line-height: 1;
			color: $black-4;
		}
	}
}
