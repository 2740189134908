@import '@/assets/styles/var.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/fonts.scss';

.snowflakesWrapp {
	display: block;
	left: 0;
	top: 0;
	position: absolute;
	width: 100%;
	height: 100%;

	.snowFlakesForBtn {
		position: absolute;

		&:nth-child(1) {
			bottom: -18px;
			left: 0px;
		}

		&:nth-child(2) {
			top: -18px;
			left: 92px;
			transform: rotate(15deg);
		}

		&:nth-child(3) {
			bottom: -14px;
			left: 144px;
			transform: matrix(-0.97, 0.26, 0.26, 0.97, 0, 0);
		}

		&:nth-child(4) {
			top: -18px;
			right: 190px;
			transform: rotate(-180deg);
		}

		&:nth-child(5) {
			bottom: -17px;
			right: 94px;
			transform: rotate(15deg);
		}

		&:nth-child(6) {
			top: -18px;
			right: 30px;
			transform: rotate(15deg);
		}
	}
}

.smartBanner {
	margin-top: 48px;
}

.wrapper {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	z-index: 101;
	cursor: pointer;
	background: rgba(41, 44, 51, 0.8);
	-ms-scroll-chaining: none;
	-webkit-overflow-scrolling: touch;
	overscroll-behavior: contain;
	user-select: none;
	will-change: transform;

	&.onEnter {
		opacity: 0;
	}

	&.onEnterActive {
		opacity: 1;
		transition: opacity 0.18s ease;
	}

	&.onExit {
		opacity: 1;
	}

	&.onExitActive {
		opacity: 0;
		transition: opacity 0.18s ease-in;
	}

	&.onEnterDone {
		.container {
			opacity: 1;
			transform: translateX(0);
		}
	}
}

.container {
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100%;
	min-width: 250px;
	width: 100%;
	max-width: 500px;
	margin-right: auto;
	opacity: 0;
	overflow: hidden;
	background: #fff;
	cursor: default;
	box-shadow: 2px 0px 12px rgba(0, 0, 0, 0.08);
	transform: translateX(-50%);
	transition: transform 0.18s linear, opacity 0.18s linear;

	&::before {
		content: '';
		position: absolute;
		z-index: 2;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.3);
		opacity: 0;
		pointer-events: none;
		transition: opacity 0.2s;
	}

	&.loading {
		&::before {
			opacity: 1;
			pointer-events: all;
		}
	}

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 0;
		width: 100%;
		height: 88px;
		background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff 32px);
	}

	.header {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-shrink: 0;
		width: 100%;
		height: 58px;
		padding-left: 32px;
		padding-right: 20px;
		margin-top: 58px;

		@include maxWidth($mobile) {
			padding-left: 20px;
		}

		&::after {
			content: '';
			position: absolute;
			bottom: 0;
			right: 0;
			display: block;
			width: calc(100% - 20px);
			height: 1px;
			background: $gray-1;
		}

		.title {
			color: $black-2;
			font-size: 20px;
			font-weight: 450;
			line-height: 130%;
			font-family: $fontFutura;
			display: flex;
		}

		.close {
			position: relative;
			z-index: 1;
			flex-shrink: 0;
			width: 55px;
			height: 55px;
			margin-right: -19px;

			&::before,
			&::after {
				content: '';
				position: absolute;
				top: 28px;
				left: 16px;
				display: block;
				width: 20px;
				height: 2px;
				border-radius: 2px;
				background: $black-1;
			}

			&::before {
				transform: rotate(45deg);
			}

			&::after {
				transform: rotate(-45deg);
			}
		}
	}
}

.main {
	flex-grow: 1;
	overflow-y: auto;
	padding: 16px 0 32px 8px;

	span {
		color: $black-1;
	}
}

.footer {
	position: relative;
	display: flex;
	justify-content: center;
	z-index: 1;

	.showAllButton {
		margin: 32px 0;
		width: 369px;

		@include maxWidth($mobile) {
			width: 335px;
		}
	}
}

.sortContainer {
	display: flex;
	align-items: center;
}

.titleSorted {
	color: $black-4;
	margin-right: 8px;

	@include maxWidth($mTablet + 1) {
		display: none;
	}
}

.titleSortedName {
	color: $black-2;

	@include maxWidth($mobile) {
		margin-left: none;
	}
}

.tabletIcon {
	@include maxWidth($mTablet + 1) {
		display: none;
	}
}

.mobileIcon {
	display: none;

	@include maxWidth($mTablet + 1) {
		display: block;
	}
}

.arrowDownIcon {
	margin-left: 6px;

	@include maxWidth($mTablet + 1) {
		display: none;
	}
}

.contentButton {
	width: 100%;
}
