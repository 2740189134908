@import '@/assets/styles/var.scss';
@import '@/assets/styles/fonts.scss';
@import '@/assets/styles/mixins.scss';

.container {
	position: relative;
	width: 100%;
	background: $white;
	overflow-x: auto;
	@include scrollBar($white, $gray-1, 4px, 4px);
}

.smartBanner {
	margin-top: 56px;
}

.list {
	height: 60px;
	font-weight: 500;
	text-align: center;
	white-space: nowrap;

	&::before,
	&::after {
		content: '';
		display: inline-block;
		vertical-align: middle;
		width: 10px;
		height: 100%;
	}

	@include maxWidth($tablet) {
		&::before,
		&::after {
			width: calc(3.1% - 10px);
		}
	}

	@include maxWidth($mTablet) {
		&::before,
		&::after {
			width: calc(4.22% - 10px);
		}
	}

	@include maxWidth($lMobile) {
		height: 56px;

		&::before,
		&::after {
			width: calc(5.35% - 10px);
		}
	}
}

.item {
	display: inline-block;
	vertical-align: top;
	height: 100%;
	cursor: default;
}

.link {
	display: flex;
	align-items: center;
	height: 100%;
	padding: 5px 12px 8px;
	font-family: $fontFutura;
	font-size: 18px;
	line-height: 130%;
	font-weight: 400;
	color: $black-1;
	transition: background 0.16s ease, opacity 0.16s ease, color 0.16s ease;

	@include noTouch() {
		&:hover {
			color: $black-3;
		}
	}
}

.activeLink {
	color: $black-3;
	background: #fff;
}

.sale {
	color: $red-1;
}
