@import '@/assets/styles/var.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/fonts.scss';

.title {
	margin-bottom: 12px;
	font-size: 18px;
	color: $black-2;
}

.list {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: -4px;
}

.item {
	display: flex;
	margin-right: 4px;
	margin-bottom: 4px;
	font-size: 14px;
	line-height: 1;
	color: $white;
	border-radius: 2px;
	overflow: hidden;

	& > button {
		cursor: pointer;
		transition: opacity 0.3s ease;

		& {
			width: 25px !important;
			height: 25px !important;
		}

		@include noTouch() {
			&:hover {
				opacity: 0.9;
			}
		}
	}

	& > span {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 25px;
		height: 100%;
		text-align: center;

		&::before {
			content: '';
			position: absolute;
			top: 3px;
			left: 0;
			display: block;
			width: 1px;
			height: calc(100% - 6px);
			background: rgba(255, 255, 255, 0.4);
		}
	}
}

.vk {
	background: #45668e;
}

.fb {
	background: #3b5998;
}
