@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/var.scss';
@import '@/assets/styles/fonts.scss';

.checkoutMain {
	@include maxWidth($mTablet + 1) {
		width: 320px;
	}
}

.main {
	position: relative;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.cardWrapper {
		position: relative;
		display: flex;
		flex-direction: column;
		color: #292c33;
		cursor: pointer;
		height: 100%;

		&:hover {
			.moveable {
				opacity: 1;
				transition: opacity 200ms;
			}
		}

		&.isCatalog {
			&:hover {
				.desc {
					.seoLink {
						@include minWidth($tablet) {
							.brand {
								top: -14px;
							}

							.title {
								top: -19px;
							}
						}
					}
				}
			}
		}

		&.isCheckout {
			flex-direction: row;
			border-bottom: 1px solid $gray-1;
			padding-bottom: 16px;
			margin-bottom: 24px;

			.desc {
				margin-top: 0;
			}

			.quantity {
				margin-top: 20px;

				@include maxWidth($mTablet + 1) {
					margin-top: 16px;
				}
			}

			.params {
				margin-top: 8px;
			}

			.header {
				height: 136px;
				margin-right: 15px;
				display: block;
				width: 94px;
				min-width: 94px;

				.img {
					picture {
						display: block;
						width: 100%;
					}
				}

				@include maxWidth($mTablet + 1) {
					height: 114px;
					margin-right: 10px;
					width: 79px;
					min-width: 79px;
				}
			}

			.brand {
				@include maxWidth($mTablet + 1) {
					font-size: 16px;
				}
			}

			.price {
				@include maxWidth($mTablet + 1) {
					margin-bottom: 0;
				}
			}
			.seoLink {
				.title {
					@include maxWidth($mTablet + 1) {
						height: auto;
						margin-bottom: 8px;
						font-size: 14px;
					}
				}
			}

			.quantity {
				color: $black-1;

				@include maxWidth($mTablet + 1) {
					margin-top: 16px;
				}
			}
		}

		&.isMini {
			.header {
				height: 150px;
				width: 101px;

				@include maxWidth($mTablet + 1) {
					height: 234px;
					width: 158px;
				}

				@include maxWidth($mobile) {
					height: 150px;
					width: 101px;
				}
			}
		}

		&.lastCardWrapper {
			margin-bottom: 0;
		}

		&.outOfStock {
			.header {
				.img {
					opacity: 0.5;
				}
			}
		}

		.cart {
			overflow: visible;

			.rowWrap {
				margin-bottom: 8px;
			}

			.styledPrice {
				margin-right: auto;
				padding-right: 15px;
			}

			.quantity {
				@include maxWidth($lMobile) {
					width: 100%;
				}
			}
		}

		&:hover {
			.header {
				.img {
					opacity: 1;
					visibility: visible;
					transform: scale(1, 1);
					transition: opacity 0.2s 0.16s ease-in, visibility 0s 0.16s, transform 0.2s 0.16s ease-in;
				}
			}
		}
	}

	.removeButton {
		position: absolute;
		top: 16px;
		right: 16px;
		z-index: 3;
		width: 26px;
		height: 26px;
		color: #292c33;
		background: #ffffff;
		border-radius: 50%;
		transition: color 0.25s ease-out, background 0.25s ease-out;

		&::before,
		&::after {
			content: '';
			position: absolute;
			top: calc(50% - 1px);
			left: calc(50% - 6px);
			width: 12px;
			height: 1px;
			background: currentColor;
		}

		&::before {
			transform: rotate(45deg);
		}

		&::after {
			transform: rotate(-45deg);
		}

		&:hover:not(:disabled) {
			color: #ffffff;
			background: #292c33;
		}
	}

	.click {
		width: 100%;
		height: 100%;
	}

	.desc {
		display: block;
		pointer-events: all;
		margin-top: 24px;

		@include maxWidth($tablet + 1) {
			margin-top: 16px;
		}

		.seoLink {
			display: block;
			z-index: 0;

			.brand {
				display: block;
				position: relative;
				top: 0;
				transition: top 0.3s;
				margin-bottom: 8px;
				@include card-brand;
				color: $black-1;

				@include maxWidth($mTablet + 1) {
					@include card-brand-mobile;
				}
			}

			.title {
				@include card-text;
				letter-spacing: 0.08px;
				position: relative;
				top: 0;
				display: block;
				margin-bottom: 8px;
				color: $black-3;
				transition: color 0.3s ease;
				height: 42px;
				transition: top 0.3s;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;

				@include maxWidth($tablet) {
					margin-bottom: 8px;
				}

				@include maxWidth($mobile + 1) {
					@include card-text-mobile;
				}
			}
		}
	}

	.buttons {
		display: flex;
		justify-content: center;
		margin-top: 12px;
	}

	.buttonsContainer {
		display: flex;

		& > button:nth-child(1) {
			min-width: auto;
			flex: 1 1;
		}
	}

	.cartBtnContiner {
		flex: 1;

		@include maxWidth($mTablet + 1) {
			max-width: 179px;
		}
	}

	.cartBtn {
		width: 100%;
		min-width: 100%;

		@include maxWidth($tablet + 1) {
			height: 40px;
		}

		@include maxWidth($mTablet + 1) {
			font-size: 16px;
			height: 32px;
		}
	}

	.moveable {
		position: absolute;
		bottom: 8px;
		left: 0;
		z-index: 3;
		display: grid;
		width: 100%;
		background-color: #fff;
		opacity: 0;
		transition: opacity 0.1s;
		padding-bottom: 4px;
		padding-top: 4px;
		min-height: 34px;

		@include maxWidth($tablet) {
			position: static;
			padding: 0;
			opacity: 1;
		}

		.wishlistSize {
			color: $black-3;
			margin-bottom: 8px;
			font-size: 16px;
		}
	}

	.header {
		position: relative;
		backface-visibility: hidden;

		&::after {
			content: '';
			display: inline-block;
			vertical-align: top;
			padding-top: 150%;
		}
	}

	.img {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
		width: 100%;
		height: 100%;
		backface-visibility: hidden;

		& img {
			width: 100%;
			height: 98.15%;
			max-width: none;
			object-fit: cover;
			opacity: 0;
			backface-visibility: hidden;
			transform-origin: 50% 50%;
			// transform: translateZ(0) scale(1, 1);
			transition: transform 0.45s ease-out, opacity 0.3s ease-in-out;

			&.loaded {
				opacity: 1;
			}

			&.template {
				object-fit: contain;
			}

			&.error {
				// content: url('data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==');
				content: url('/images/svg/pattern-order-picture.svg');
				object-fit: contain;
				opacity: 1;
			}
		}

		&.hovered {
			opacity: 0;
			visibility: hidden;
			transform: scale(1.1);
			transform-origin: center;
			transition: opacity 0.3s ease-out, visibility 0s 0.3s, transform 0.3s ease-out;

			&::before {
				content: none;
			}

			@include noTouch() {
			}
		}
	}

	.price {
		display: grid;
		grid-template-columns: max-content minmax(0, 1fr);
		grid-gap: 8px;
		align-items: flex-start;
		width: 100%;

		&.singleCell {
			grid-template-columns: minmax(0, 1fr);
		}
	}

	.empty {
		@include card-price;
		margin-bottom: 16px;
		color: $black-1;

		@include maxWidth($mTablet + 1) {
			@include card-price-mobile;
		}
	}

	.quantityWrapper {
		margin-bottom: 0 !important;
		height: 24px;
		width: 100%;
		position: relative;
		margin-bottom: 16px;
		.quantity {
			position: absolute;
			left: 0;
			// top:0;
			z-index: 2;
			display: flex;

			pointer-events: all;

			.quantityController {
				display: flex;
				align-items: center;
				&.disabled {
					svg {
						path,
						rect {
							fill: $black-4;
						}
					}
				}
			}
		}
	}

	.quantityBtn {
	}

	.quantityInput {
		width: 24px;
		text-align: center;
		cursor: default;
	}

	.rowWrap {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;

		@include withLoadingAnimation();

		& > * {
			margin-bottom: 8px;
		}
	}

	.params {
		display: block;
		&.empty {
			opacity: 0.6;
		}

		&.divider {
			border-top: 1px solid $gray-1;
			padding-top: 12px;
		}
	}

	.attributes {
		margin-bottom: 20px;

		dt {
			padding-right: 2px;
			color: $black-3;
			letter-spacing: 0.4px;
		}

		.attribute {
			display: flex;
			flex-wrap: wrap;
			font-size: 14px;

			dt {
				padding-right: 6px;
			}

			dd {
				color: $black-1;
			}

			&:not(:last-child) {
				margin-bottom: 4px;
			}
		}
	}

	.signs {
		position: absolute;
		bottom: 0;
		z-index: 2;
		width: 100%;
		display: flex;

		.featureList {
			display: flex;
			flex-grow: 1;
			flex-wrap: wrap;
			margin-right: -4px;
			padding: 12px 0 8px 12px;

			.item {
				max-width: 100%;
				margin-right: 4px;
			}
		}

		.fav {
			flex-shrink: 0;
			margin-left: auto;
		}
	}
}

.quantity {
	display: block;
	@include card-text;
	color: $black-3;
}
