@use "sass:math";
@import '@/assets/styles/var.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/fonts.scss';

.page {
	margin-top: 32px;
	margin-bottom: 144px;

	.seo {
		margin-top: 40px;
		font-size: 22px;
		line-height: 1.3;

		@include maxWidth($tablet + 1) {
			margin-top: 32px;
		}

		@include maxWidth($lMobile) {
			margin-top: 24px;
		}

		font-size: 22px;
		line-height: 1.3;

		strong,
		b {
			font-family: $fontFutura;
			font-weight: 500;
		}

		a {
			color: $black-3;
			border-bottom: 1px dashed currentColor;
			transition: color 0.3s ease, border-color 0.3s ease;

			&:hover {
				color: $black-1;
			}

			&:only-child {
				font-size: 16px;
			}
		}

		p {
			width: 100%;
			margin-bottom: 20px;
			white-space: pre-wrap;
			font-family: $fontFuturaBook;
			color: $black-2;

			&:last-child {
				margin-bottom: 0;
			}
		}

		h2,
		h3 {
			font-family: $fontFutura;
			font-weight: 500;
			color: $black-1;
			font-size: 32px;
			line-height: 1.2;

			&:not(:first-child) {
				margin-top: 40px;

				@include maxWidth($lMobile) {
					margin-top: 32px;
				}
			}

			&:not(:last-child) {
				margin-bottom: 24px;
			}
		}

		h3 {
			font-size: 24px;
			line-height: 1.3;
		}

		ul,
		ol {
			margin-top: 24px;
			margin-bottom: 24px;
			padding-left: 34px;

			@include maxWidth($lMobile) {
				margin-top: 20px;
				margin-bottom: 20px;
			}

			li {
				position: relative;
				margin-bottom: 2px;
			}
		}

		ul > li::before {
			content: '';
			position: absolute;
			top: 12px;
			right: calc(100% + 16px);
			display: block;
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background: $black-1;
		}
	}
}

.breadCrumbs {
	margin-bottom: 22px;
}

.header {
	display: flex;
	align-items: baseline;
	justify-content: space-between;
	margin-bottom: 24px;
}

.left {
	display: flex;
	align-items: baseline;

	@include maxWidth($mTablet + 1) {
		flex-wrap: wrap;
	}
}

.title {
	padding-right: 16px;
	font-weight: 400;

	@include maxWidth($mTablet + 1) {
		white-space: normal;
	}
}

.productsQuantity {
	color: $black-3;
	font-size: 16px;
	line-height: 21px;
	width: 130px;
}

.right {
	display: flex;
	align-items: center;
	min-width: 254px;
	margin-top: 16px;

	@include maxWidth($sDesktop) {
		display: none;
	}
}

.sortLabel {
	@include card-text;
	color: $black-4;
	margin-right: 8px;
}

.content {
	display: flex;
}

.aside {
	width: 292px;
	flex-shrink: 0;

	@include maxWidth($sDesktop) {
		display: none;
	}
}

.scrollBox {
	height: calc(100vh - 290px);
	max-height: 100%;
	position: sticky;
	top: 70px;
	overflow-y: auto;
	padding-right: 16px;
	@include scrollBar();
}

.main {
	width: calc(100% - 292px);
	margin-left: 32px;

	@include maxWidth($sDesktop) {
		width: 100%;
		margin-left: 0;
	}
}

.treeMobile {
	display: none;

	@include maxWidth($sDesktop) {
		display: block;
		margin-bottom: 32px;
		width: 100vw;
		margin-left: -3.125%;
	}

	@include maxWidth($mTablet + 1) {
		margin-left: -4.17%;
	}

	@include maxWidth($lMobile + 1) {
		margin-left: -5.95%;
	}
}

.filterToggles {
	display: none;

	@include maxWidth($sDesktop) {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 32px;
	}

	@include maxWidth($tablet + 1) {
		margin-bottom: 24px;
	}
}

.tags {
	margin-top: 48px;
}

.appliedFilters {
	@include maxWidth($tablet + 1) {
		display: none;
	}
}

.products {
	&:not(:last-child) {
		margin-bottom: 48px;
	}
}

.productsEmpty {
	position: relative;
	font-size: 22px;

	&::before {
		content: 'К сожалению, на данный момент все товары из этого раздела распроданы. Предлагаем Вам ознакомиться с другими новинками каталога. Мы подготовили для Вас много интересных предложений.';
	}

	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		z-index: 5;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.6);
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		transition: opacity 0.3s, visibility 0s 0.3s;
	}
}

.productsLoading {
	&::after {
		opacity: 1;
		visibility: visible;
		pointer-events: all;
		transition: opacity 0.3s, visibility 0s;
	}
}

.disabledBlock {
	pointer-events: none;
	opacity: 0.5;
}
