@import '@/assets/styles/var.scss';
@import '@/assets/styles/fonts.scss';
@import '@/assets/styles/mixins.scss';

.onEnter {
	opacity: 0;
}

.onEnterActive {
	opacity: 1;
	transition: opacity 0.23s ease-out;
}

.onExit {
	opacity: 1;
}

.onExitActive {
	opacity: 0;
	transition: opacity 0.23s ease-in;
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2147483647;
	overflow-x: hidden;
	overflow-y: auto;
	overscroll-behavior: contain;
	text-align: center;
	cursor: pointer;
	background-color: rgba(41, 44, 51, 0.8);
	--modal-dialog-padding: 48px 40px;

	@include maxWidth($mobile) {
		--modal-dialog-padding: 24px 40px 24px 24px;
	}
}

.brandInfo {
	--modal-dialog-max-width: 832px;
}

.sizeTable {
	--modal-dialog-max-width: 920px;
}

.container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-height: 100%;
	pointer-events: none;
}

.closeDialogWrapper {
	position: relative;
	max-width: 100%;
	padding: 20px;
	padding-right: 56px;
	pointer-events: none;

	&::after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		z-index: 5;
		width: 56px;
		height: 56px;
		background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.294 13.02a1 1 0 010-1.413L11.608.293a1 1 0 111.414 1.414L1.708 13.021a1 1 0 01-1.414 0z' fill='%23fff'/%3E%3Cpath d='M13.02 13.02a1 1 0 01-1.413 0L.293 1.708A1 1 0 111.707.293l11.314 11.314a1 1 0 010 1.414z' fill='%23fff'/%3E%3C/svg%3E");
		background-size: 14px 14px;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		pointer-events: all;
		cursor: pointer;
		transition: opacity 0.25s ease;
	}

	@include noTouch() {
		&:hover {
			&::after {
				opacity: 0.8;
			}
		}
	}

	@include maxWidth($mobile) {
		padding-right: 20px;

		&::after {
			width: 89px;
			height: 89px;
			background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.294 13.02a1 1 0 010-1.413L11.608.293a1 1 0 111.414 1.414L1.708 13.021a1 1 0 01-1.414 0z' fill='%23fff'/%3E%3Cpath d='M13.02 13.02a1 1 0 01-1.413 0L.293 1.708A1 1 0 111.707.293l11.314 11.314a1 1 0 010 1.414z' fill='%23fff'/%3E%3C/svg%3E"),
				url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12.5' cy='12.5' r='12.5' fill='%23292C33'/%3E%3C/svg%3E");
			background-size: 8px 8px, 25px 25px;
		}
	}
}

.dialog {
	width: 100%;
	height: auto;
	max-width: var(--modal-dialog-max-width, 1264px);
	background-color: #fff;
	cursor: default;
	pointer-events: all;
	padding: var(--modal-dialog-padding, 0);
	border-radius: 4px;

	@include maxWidth($mobile) {
		padding: 24px;
	}
}
