@import '@/assets/styles/mixins.scss';

.image {
	&::after {
		content: none;
	}

	img {
		position: static;
		height: auto;
	}
}
