@import '@/assets/styles/var.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/fonts.scss';

.main {
	display: grid;
	grid-gap: 48px 32px;
	grid-template-columns: repeat(2, minmax(0, 1fr));

	@include maxWidth($lMobile) {
		grid-template-columns: minmax(0, 1fr);
	}
}

.item {
	position: relative;

	&:only-child {
		grid-column: 1 / -1;

		--nav-picture-image-height: 50%;
		--nav-picture-content-pb: 48px;
		--nav-picture-title-size: 40px;
		--nav-picture-title-mb: 16px;
		@include maxWidth($lMobile) {
			--nav-picture-title-size: 32px;
		}
	}
}

.image {
	position: relative;
	width: 100%;
	height: auto;
	overflow: hidden;

	&::after {
		content: '';
		display: inline-block;
		vertical-align: middle;
		width: 0;
		padding-top: var(--nav-picture-image-height, 75%);
	}

	& img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.content {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	height: 100%;
	padding: 20px;
	padding-bottom: var(--nav-picture-content-pb, 40px);
	color: #fff;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.7) 100%);
	pointer-events: none;

	@include maxWidth($lMobile) {
		position: static;
		height: auto;
		padding: 0;
		padding-top: 24px;
		color: $black-2;
		background: 0 0;
		pointer-events: all;
	}
}

.title {
	display: block;
	max-width: 670px;
	font-size: var(--nav-picture-title-size, 32px);
	line-height: 1.2;
	color: #fff;
	text-align: center;

	&:not(:last-child) {
		margin-bottom: var(--nav-picture-title-mb, 8px);
	}

	@include maxWidth($lMobile) {
		color: $black-1;
	}
}

.desc {
	max-width: 620px;
	font-size: 24px;
	line-height: 130%;

	@include maxWidth($mTablet) {
		font-size: 20px;
	}
}
