@import '@/assets/styles/var.scss';
@import '@/assets/styles/fonts.scss';
@import '@/assets/styles/mixins.scss';

.main {
	overflow: hidden;
	width: 100%;
	height: 34px;
}

.wrap {
	display: flex;
	overflow-x: auto;
	height: 45px;
	padding: 0 calc((100% - $containerDefault) / 2 - 10px);

	@include maxWidth($tablet) {
		padding: 0 calc((100% - $containerTablet) / 2 - 2px);
	}

	@include maxWidth($mTablet + 1) {
		padding: 0 calc((100% - $containerMTablet) / 2 - 2px);
	}
}

.point {
	border: 1px solid $gray-1;
	border-radius: 20px;
	padding: 0 13px;
	height: 34px;
	display: flex;
	justify-content: center;
	align-items: center;
	white-space: nowrap;
	@include card-text;
	letter-spacing: 0.005em;
	color: $black-3;

	&:not(:last-child) {
		margin-right: 8px;
	}
}
