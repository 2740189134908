@import '@/assets/styles/var.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/fonts.scss';

.news {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 32px 32px;
	margin-bottom: 32px;

	@include maxWidth($tablet + 1) {
		grid-gap: 32px 24px;
	}

	@include maxWidth($mTablet + 1) {
		margin-bottom: 48px;
	}

	@include maxWidth($lMobile) {
		grid-gap: 32px;
	}
}

.link {
	position: relative;
	display: flex;
	height: 100%;

	@include maxWidth($mTablet + 1) {
		flex-wrap: wrap;
		height: auto;
	}
}

.content {
	position: relative;
	z-index: 1;
	flex-grow: 1;
	margin-right: 8px;
	padding-bottom: 8px;
	border-bottom: 1px solid $gray-1;

	@include maxWidth($tablet + 1) {
		margin-right: 24;
	}

	@include maxWidth($mTablet + 1) {
		margin-right: 0;
		padding-bottom: 0;
		border-bottom: none;
	}
}

.title {
	width: 100%;
	margin-bottom: 8px;
	display: block;
	display: -webkit-box;
	height: auto;
	max-height: calc(24px * 1.3 * 3);
	-webkit-line-clamp: 3;
	-moz-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	color: $black-1;
	@include h3;
	font-weight: 450;

	@include maxWidth($tablet + 1) {
		max-height: calc(20px * 1.3 * 3);
		margin-top: -6px;
		font-size: 20px;
	}

	@include maxWidth($mTablet + 1) {
		display: block;
		max-height: none;
		margin-top: 0;
		@include h3-mobile;
	}

	@include maxWidth($lMobile + 1) {
		margin-bottom: 8px;
	}
}

.date {
	font-size: 14px;
	color: $black-4 !important;
}

.img {
	position: relative;
	flex-shrink: 0;
	width: calc((100% - 24px) * 0.4762);
	transition: opacity 0.3s ease;

	&::after {
		content: '';
		display: inline-block;
		vertical-align: top;
		width: 100%;
		padding-top: 66.66%;
	}

	& img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	@include maxWidth($tablet + 1) {
		width: 222px;
		height: 141px;
	}

	@include maxWidth($mTablet + 1) {
		height: 100%;
		order: -1;
		width: 100%;
		margin-bottom: 16px;
	}
}

.item {
	&:nth-child(3n) {
		margin-bottom: 16px;

		@include maxWidth($mTablet + 1) {
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	&:hover {
		.img {
			opacity: 0.85;
		}
	}

	@include maxWidth($lMobile) {
		grid-column: 1 / span 2;
	}
}

.special {
	grid-row: span 2;
	margin-bottom: 16px;

	@include maxWidth($mTablet + 1) {
		margin-bottom: 0;
	}

	.link {
		height: 100%;
	}

	.content {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		// height: 100%;
		display: flex;
		align-items: flex-end;
		align-content: flex-end;
		flex-wrap: wrap;
		margin-right: 0;
		padding: 20px;
		border-bottom: none;
		background: linear-gradient(transparent, #000);
		opacity: 0.85;

		@include maxWidth($mTablet + 1) {
			padding: 32px;
		}

		@include maxWidth($lMobile) {
			position: static;
			display: block;
			padding: 0;
			height: auto;
			background: inherit;
		}
	}

	.title {
		max-height: calc(40px * 1.1 * 3);
		margin-top: 0;
		margin-bottom: 24px;
		@include h3;
		font-weight: 450;
		color: $white;

		@include maxWidth($tablet + 1) {
			max-height: calc(38px * 1.1 * 3);
		}

		@include maxWidth($mTablet + 1) {
			display: -webkit-box;
			max-height: calc(40px * 1.1 * 3);
			@include h3-mobile;
		}

		@include maxWidth($lMobile) {
			display: block;
			max-height: none;
			margin-bottom: 8px;
			font-size: 24px;
			line-height: 1.3;
			color: $black-1;
		}
	}

	.date {
		opacity: 0.85;
		color: $white;

		@include maxWidth($lMobile) {
			opacity: 1;
			color: $black-4;
		}
	}

	.img {
		position: relative;
		z-index: 0;
		width: 100%;
		height: 100%;

		@include maxWidth($mTablet + 1) {
			margin-bottom: 0;
		}

		@include maxWidth(650px) {
			height: auto;
		}

		@include maxWidth($lMobile) {
			margin-bottom: 16px;
		}

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			z-index: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);

			@include maxWidth($lMobile) {
				content: none;
			}
		}
	}

	@include maxWidth($mTablet + 1) {
		grid-column: 1 / span 2;
	}
}
