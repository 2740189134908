@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/var.scss';
@import '@/assets/styles/fonts.scss';

.shopsMap {
	&.map {
		height: var(--shops-map-height, calc(100vh - 120px));

		@include maxWidth($tablet) {
			height: var(--shops-map-height, calc(100vh - 60px));
		}

		@include maxWidth($mTablet) {
			height: var(--shops-map-height, calc(100vh - 56px));
		}

		.map-wrapper {
			width: 100%;
			overflow: hidden;
			height: var(--shops-map-height, calc(100vh - 120px));

			* {
				touch-action: auto;
			}

			@include maxWidth($tablet) {
				height: var(--shops-map-height, calc(100vh - 60px));
			}

			@include maxWidth($tablet) {
				height: var(--shops-map-height, calc(100vh - 56px));
			}
		}
	}
}
