@import '@/assets/styles/var.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/fonts.scss';

.main {
	display: grid;
	grid-gap: 32px;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	position: relative;

	@include maxWidth($tablet + 1) {
		grid-gap: 24px;
	}

	@include maxWidth($mobile + 1) {
		grid-gap: 16px;
		grid-template-columns: minmax(0, 1fr);
	}
}

.image {
	position: relative;
	width: 616px;
	height: 462px;
	overflow: hidden;

	@include maxWidth($xmDesktop + 1) {
		width: 100%;
	}

	@include maxWidth($tablet + 1) {
		width: 468px;
		height: 351px;
	}

	@include maxWidth($tablet) {
		width: 100%;
	}

	@include maxWidth($mTablet + 1) {
		width: 341px;
		height: 255px;
	}

	@include maxWidth($mTablet) {
		width: 100%;
	}

	@include maxWidth($mobile + 1) {
		width: 100%;
		margin: auto;
		height: auto;
	}

	@include maxWidth(376px) {
		width: 335px;
		margin: auto;
		height: 251px;
	}

	@include maxWidth(374px) {
		width: 90vw;
		margin: auto;
		height: auto;
	}

	&::after {
		content: '';
		display: inline-block;
		vertical-align: middle;
		width: 0;
		padding-top: var(--nav-picture-image-height, 75%);
	}

	& img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.contentBlock {
	display: flex;
	align-items: center;

	@include maxWidth($mobile + 1) {
		align-items: normal;
	}
}

.content {
	color: $black-1;
	margin-right: auto;
	margin-left: auto;

	.title {
		margin-bottom: 8px;
	}

	.description {
		font-size: 20px;
		font-weight: 400;
		line-height: 130%;
		color: $black-2;
		margin-bottom: 8px;

		@include maxWidth($mTablet + 1) {
			font-size: 18px;
		}
	}

	.text {
		margin-bottom: 4px;
		font-size: 20px;
		font-weight: 400;
		line-height: 130%;
		color: $black-3;
		text-decoration: underline;

		@include maxWidth($mTablet + 1) {
			font-size: 18px;
		}
	}
}
