@import '@/assets/styles/var.scss';
@import '@/assets/styles/fonts.scss';
@import '@/assets/styles/mixins.scss';

.banner {
	height: 56px;
	background-color: $black-1;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: 9px;
	padding-right: 20px;

	.appInfo {
		display: flex;
		align-items: center;

		.icon {
			margin-right: 12px;
		}

		.logoImage {
			margin-right: 8px;
			height: 32px;
		}

		.text {
			color: $white;
			font-size: 12px;
			line-height: 120%;
			width: 65%;
		}
	}

	.button {
		background-color: $white;
		color: $black-1;
		font-size: 16px;
		line-height: 120%;
		padding: 2px 8px 4px 8px;
		border-radius: 4px;
	}
}
