@import '@/assets/styles/var.scss';
@import '@/assets/styles/fonts.scss';
@import '@/assets/styles/mixins.scss';

.container {
	position: relative;
}

.form {
	position: relative;
	width: 100%;
	border-bottom: 1px solid $black-4;
	transition: opacity 0.23s linear, visibility 0s 0.23s;

	@include maxWidth($sDesktop) {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 12;
		width: 100%;
		height: 58px;
		opacity: 0;
		visibility: hidden;

		&.visible {
			opacity: 1;
			visibility: visible;
			transition: opacity 0.23s linear, visibility 0s;
		}
	}

	@include maxWidth($lMobile) {
		height: 56px;
	}

	&.newYear {
		.input {
			color: $white;
			caret-color: $white;
			background-color: $black-2;
		}

		.resetBtn {
			&::before,
			&::after {
				background: $white;
			}
		}

		.submitBtn {
			svg path {
				fill: $white;
			}
		}
	}
}

.dialogRoot {
	@include minWidth($tablet) {
		display: block !important;
	}

	.dialog {
		margin: 55px 0 0 0;
		border-radius: 0;
		max-width: none;
		width: 100%;
		height: 670px;

		@include minWidth($sDesktop + 2) {
			margin: 63px 0 0 0;
		}

		@include maxWidth($tablet + 1) {
			max-height: calc(100% - 55px);
			height: 100%;
		}

		.dialogMain {
			height: 100%;
			margin: 8px 0 0 0;
		}
	}
}

.smallDialog {
	@include minWidth($mTablet + 1) {
		height: 82px !important;
	}
}

.mainBlock {
	margin-bottom: 20px !important;

	.warningInfo {
		margin-top: 32px;
		color: $black-1;
		@include h3;

		@include maxWidth($mTablet + 1) {
			margin-top: 24px;
			@include h3-mobile;
		}
	}
}

.inputBlock {
	display: flex;
	margin-top: 8px;

	.inputGeneral {
		margin-right: 20px;
		width: 100%;
	}

	.inputField {
		padding-left: 46px;
		padding-right: 46px;
		border-radius: 24px;
	}

	.cancelSearch {
		font-size: 18px;

		span {
			border-bottom: none !important;
		}
	}
}

.resultBlock {
	margin-top: 8px;
	padding: 8px 48px 0 48px;

	@include maxWidth($tablet + 1) {
		padding-left: 0;
		padding-right: 0;
		font-size: 18px;
		margin-bottom: 14px;
	}

	@include maxWidth($tablet) {
		padding: 8px 48px 0 48px;
		font-size: 18px;
		margin-bottom: 14px;
	}

	@include maxWidth($mobile) {
		flex-direction: column;
		padding-left: 0;
		padding-right: 0;
	}

	&.resultBlockDesktop {
		display: flex;

		@include maxWidth($tablet) {
			display: none;
		}
	}

	&.resultBlockMobile {
		display: none;

		@include maxWidth($tablet) {
			display: flex;
			justify-content: space-between;
		}

		@include maxWidth($mobile) {
			flex-direction: column;
		}

		.resultCatInfoBlock {
			width: 236px;

			@include maxWidth($mobile) {
				width: 100%;
			}
		}
	}
}

.blockTitle {
	color: $black-1;
	font-weight: 450;
	font-size: 20px;
	line-height: 130%;
	margin-bottom: 16px;
	font-family: $fontFutura;

	@include maxWidth($tablet) {
		font-size: 18px;
		margin-bottom: 14px;
	}
}

.result {
	display: flex;
	align-items: center;
	transition: background-color 0.2s ease;
	margin-bottom: 8px;

	@include noTouch() {
		&:hover {
			background-color: $gray-1;
		}
	}
}

.image {
	position: relative;
	flex-shrink: 0;
	width: 68px;
	margin-right: 28px;

	&::after {
		content: '';
		display: block;
		padding-top: 150%;
	}

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.brand {
	color: $black-1;
	font-weight: 400;
	font-size: 14px;
	line-height: 130%;
	letter-spacing: 0.005em;
	margin-bottom: 4px;
}

.resultTitle {
	color: $black-3;
	font-weight: 400;
	font-size: 14px;
	line-height: 130%;
	letter-spacing: 0.005em;
	margin-bottom: 4px;
}

.resultBlock {
	.price {
		display: grid;
		grid-template-columns: -webkit-max-content minmax(0, 1fr);
		grid-template-columns: max-content minmax(0, 1fr);
		grid-gap: 8px;
		-webkit-align-items: flex-start;
		align-items: flex-start;
		width: 100%;

		&.withDiscount {
			color: $red-1;
		}

		div {
			font-weight: 450;
			font-size: 14px !important;
			line-height: 130%;
		}
	}
}

.oftenSearch {
	width: 248px;
	margin-right: 48px;

	@include maxWidth($tablet + 1) {
		width: 212px;
		margin-right: 32px;
	}

	@include maxWidth($tablet) {
		margin-bottom: 32px;
	}

	@include maxWidth($mobile) {
		width: 100%;
		border-bottom: 1px solid $gray-1;
		padding-bottom: 16px;
		margin-bottom: 16px;
	}

	a {
		div {
			font-weight: 400;
			font-size: 16px;
			line-height: 130%;
			color: $black-2;

			b {
				color: $black-1;
			}
		}
	}
}

.goods {
	width: 302px;
	margin-right: 48px;

	@include maxWidth($tablet + 1) {
		width: 248px;
		margin-right: 32px;
	}

	@include maxWidth($tablet) {
		width: 302px;
		margin-right: 0;
	}

	@include maxWidth($mobile) {
		width: 100%;
		margin-bottom: 48px;
	}

	.allResults {
		width: 100%;
		margin-top: 36px;

		@include maxWidth($mobile) {
			position: absolute;
			bottom: 20px;
			width: 90%;
		}
	}
}

.catalog {
	display: flex;
	margin-left: 48px;

	@include maxWidth($tablet + 1) {
		margin-left: 32px;
	}

	@include maxWidth($tablet) {
		flex-direction: column;
		margin-left: 0;
	}

	.categories,
	.brands {
		margin-right: 48px;
		width: 212px;

		@include maxWidth($tablet + 1) {
			margin-right: 32px;
			width: 186px;
		}

		@include maxWidth($tablet) {
			margin-right: 0;
			width: auto;
			margin-bottom: 32px;
		}

		@include maxWidth($mobile) {
			width: 100%;
			border-bottom: 1px solid $gray-1;
			padding-bottom: 16px;
			margin-bottom: 16px;
		}
	}

	.catalogLink {
		font-weight: 400;
		font-size: 16px;
		line-height: 130%;
		color: $black-2;
		margin-bottom: 8px;
		display: flex;
		justify-content: space-between;

		button {
			&::after {
				content: '';
				display: block;
				vertical-align: middle;
				width: 12px;
				height: 12px;
				color: $black-1;
				background-image: url("data:image/svg+xml,%3Csvg width='12' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.869 2.625a.8.8 0 00-1.132 1.131l2.546 2.547-2.546 2.546A.8.8 0 104.87 9.98l3.11-3.111a.798.798 0 000-1.133L4.87 2.625z' fill='%23171717'/%3E%3C/svg%3E");
			}
		}
	}
}

.smartBanner {
	margin-top: 55px;
}

.noMargin {
	margin-bottom: 0;
}
