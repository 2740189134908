@import '@/assets/styles/var.scss';
@import '@/assets/styles/fonts.scss';

.code-form {
	padding: 0 20px;
	margin-bottom: 104px;

	.label {
		padding: 0;
	}

	.form-control {
		margin-bottom: 24px;
		text-align: left;
	}

	.form-info {
		letter-spacing: 0.005em;
		color: $black-3;
		margin-top: 8px;
		@include card-text;
	}

	.resend-code-btn {
		display: inline-block;
		min-width: auto;
		margin-top: 8px;
	}

	.submit-btn {
		margin-bottom: 16px;
		min-width: 100%;
	}

	.tip-container {
		text-align: center;
		position: relative;
		display: flex;
		justify-content: center;

		.tip-btn {
			width: auto;
			font-size: 14px;

			span,
			span:hover {
				border-style: dashed;
			}
		}

		.tip-dropdown {
			position: absolute;
			left: 0;
			top: calc(100% + 4px);
			z-index: 2;
			width: 100%;
			padding: 12px;
			font-size: 14px;
			line-height: 1.3;
			background: $white;
			box-shadow: 0px 0px 12px rgba($black, 0.12);
			border-radius: 4px;
			opacity: 0;
			text-align: left;
			visibility: hidden;
			transform: translate3d(0, 10px, 0);
			transition: transform 0.2s ease, opacity 0.1s 0.1s, visibility 0s 0.2s;

			&.visible {
				opacity: 1;
				visibility: visible;
				transform: translate3d(0, 0, 0);
				transition: transform 0.2s ease, opacity 0.1s;
			}

			p {
				&:first-child {
					margin-bottom: 12px;
				}
			}
		}
	}

	@media screen and (max-width: $mTablet) {
		padding: 0;
	}
}
