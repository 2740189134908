@import '@/assets/styles/var.scss';
@import '@/assets/styles/fonts.scss';
@import '@/assets/styles/mixins.scss';

.priceContainer {
	display: flex;
	flex-wrap: wrap;
	flex-shrink: 0;
	max-width: 100%;
}

.font {
	@include card-price;
	display: flex;

	@include maxWidth($mobile + 1) {
		@include card-price-mobile;
	}
}

.oldPrice {
	color: $black-1;
	margin-right: 10px;
}

.newPrice {
	text-decoration: line-through;
	color: $black-3;
}

.discountPrice {
	color: $red-1;
}

.fontCheckout {
	font-size: 16px;

	@include maxWidth($mTablet + 1) {
		@include card-price-mobile;
	}
}

.fontProductPage {
	font-size: 24px;

	@include maxWidth($mTablet + 1) {
		font-size: 20px;
	}
}
