@import '@/assets/styles/var.scss';

.container {
	display: flex;
	flex-direction: column;
	height: 100%;
	min-width: 320px;
}

.content {
	flex: 1 0 auto;
}
