@import '@/assets/styles/var.scss';
@import '@/assets/styles/fonts.scss';

.form-title {
	margin-bottom: 32px;
	font-weight: 450;
	color: $black;
	@include h2;
	width: 95%;
	margin-left: auto;
	margin-right: auto;

	@media screen and (max-width: $mTablet) {
		padding: 0 20px;
		@include h2-mobile;
	}
}

.form-text {
	color: $black-2;
	text-align: center;
	margin-bottom: 32px;
	color: $black;
	@include content-book;

	p:first-child {
		margin-bottom: 12px;
	}

	@media screen and (max-width: $mTablet) {
		@include content-book-mobile;
	}
}

.change-btn {
	display: inline-block;
	min-width: auto;
	margin-left: 16px;
}

.combine-products-btn {
	display: inline-block;
	padding: 1px 8px 3px;
	font-size: 14px;
	color: $white !important;
	border: 1px solid $white !important;
	border-radius: 4px;
	transition: opacity 0.16s;
	width: 116px;
	height: 36px;

	&:not(:first-child) {
		margin-top: 16px;
	}

	&:hover {
		opacity: 0.7;
	}
}
