@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/var.scss';

.button {
	max-height: 44px;
	width: 44px;
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 16px;

	@include maxWidth($tablet + 1) {
		width: 40px;
	}

	@include maxWidth($mTablet + 1) {
		width: 32px;
		margin-left: 8px;
	}
}

.large {
	height: 44px;
	width: 44px;
}

.small {
	height: 24px;
	width: 24px;
}

.img {
	width: 20px;
	height: 19px;
	max-width: unset;
	border-radius: 2px;
}

.largeImg {
	width: 21px;
	height: 19px;
}

.border {
	border: 1px solid $green-1;
	border-radius: 26px;
}

@media (min-width: 1024px) {
	.button:hover {
		background: no-repeat url('/images/svg/like.svg');
		background-position: center;
		background-size: 16px;
	}

	.large:hover {
		background-size: 20px;
	}
}

@media (max-width: 768px) {
	.img {
		width: 14px;
		height: 12px;
	}

	.largeImg {
		width: 18px;
		height: 16px;
	}
}

.catalogLike {
	position: absolute;
	bottom: 10px;
	right: 8px;
	border: none;
	z-index: 100;

	@media (max-width: 768px) {
		bottom: 16px;

		.img {
			width: 22px;
			height: 22px;
		}
	}
}
