@import '@/assets/styles/var.scss';

.breadCrumbs {
	margin-top: 32px;
	margin-bottom: 22px;
}

.title {
	color: $black-1;
}

.cartSection {
	padding-bottom: 144px;
}

.cartContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 400px;
}

.emptyText {
	text-align: center;
}
