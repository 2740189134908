@import '@/assets/styles/mixins.scss';

@keyframes marqueeAnimation {
	0% {
		transform: translate(0, 0);
	}

	100% {
		transform: translate(-100%, 0);
	}
}

.marquee {
	font-size: var(--marquee-fz, 32px);
	white-space: nowrap;
	overflow: hidden;

	@include maxWidth($lMobile) {
		font-size: var(--marquee-fz, 24px);
	}
}

.marquee-content {
	display: inline-block;
	padding-left: 100%;
	animation-timing-function: linear;
	animation-delay: 0s;
	animation-iteration-count: infinite;
	animation-direction: normal;
	animation-fill-mode: none;
	animation-play-state: running;
	animation-name: marqueeAnimation;
}
