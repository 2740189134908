@import '@/assets/styles/var.scss';
@import '@/assets/styles/fonts.scss';
@import '@/assets/styles/mixins.scss';

.valueContainer {
	// @include maxWidth($mobile + 1) {
	//     height: 60px !important;
	// }
}

.valueContainerDisabled {
	color: $black-3 !important;
}

.option {
	color: $black-1;
	cursor: default;
	display: block;
	font-size: inherit;
	padding: 8px 12px;
	width: 100%;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	box-sizing: border-box;
	font-size: 20px !important;
	line-height: 130%;
	border-radius: 18px;

	&:hover {
		background-color: $green-2;
		color: $white;

		.info {
			color: $white;
		}
	}

	.info {
		margin-top: 2px;
		font-size: 14px;
		line-height: 130%;
		color: $black-3;
	}

	.selected {
		color: $white;
	}
}

.select {
	width: 100%;
	font-size: 20px;
	line-height: 26px;
}

.scrollbar {
	max-height: 210px;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 14px;
	}

	&::-webkit-scrollbar-thumb {
		border: 4px solid rgba(0, 0, 0, 0);
		background-clip: padding-box;
		border-radius: 9999px;
		background-color: $gray-1;
	}
}

.valueContainer80 {
	@include maxWidth($tablet + 1) {
		height: 50px !important;
	}

	@include maxWidth(826px) {
		height: 68px !important;
	}

	@include maxWidth($mTablet + 1) {
		height: 44px !important;
	}

	@include maxWidth($mobile) {
		height: 50px !important;
	}

	@include maxWidth($slMobile) {
		height: 68px !important;
	}
}

.valueContainer90 {
	@include maxWidth($mobile) {
		height: 60px !important;
	}
}

.valueContainer115 {
	@include maxWidth($tablet + 1) {
		height: 68px !important;
	}

	@include maxWidth($mTablet + 1) {
		height: 44px !important;
	}

	@include maxWidth($mobile) {
		height: 88px !important;
	}
}

.valueContainer105 {
	height: 68px !important;

	@include maxWidth($mobile) {
		height: 88px !important;
	}
}

.valueContainer125 {
	height: 68px !important;

	@include maxWidth($tablet + 1) {
		height: 88px !important;
	}

	@include maxWidth($mobile) {
		height: 114px !important;
	}
}
