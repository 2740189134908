@import '@/assets/styles/var.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/fonts.scss';

.container {
	.productList {
		position: relative;
		display: grid;
		grid-template-columns: repeat(4, minmax(0, 1fr));
		grid-gap: 32px;

		@include maxWidth($tablet + 1) {
			grid-template-columns: repeat(3, minmax(0, 1fr));
			grid-gap: 32px 24px;
		}

		@include maxWidth($lMobile + 1) {
			grid-template-columns: repeat(2, minmax(0, 1fr));
			grid-gap: 32px 20px;
		}

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			z-index: 5;
			width: 100%;
			height: 100%;
			background-color: rgba(255, 255, 255, 0.6);
			opacity: 0;
			visibility: hidden;
			pointer-events: none;
			transition: opacity 0.3s, visibility 0s 0.3s;
		}
	}
}
