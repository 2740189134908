@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/var.scss';
@import '@/assets/styles/fonts.scss';

.productFeature {
	padding: 4px 6px;
	color: #fff;
	background-color: $black-1;
	text-transform: uppercase;
	border-radius: 6px;

	&.text {
		padding: 3px 6px 3px;
		color: $black-1;
		background-color: #fff;
	}

	&.new {
		color: #292c33;
		background-color: #d4dccd;
	}

	&.sale {
		color: $red-1;
		background-color: $red-3;
	}

	&.alternative {
		background-color: #d5a021;
	}

	&.city {
		color: $black-1;
		background-color: #e8e4d6;
	}

	&.accent {
		color: $black-1;
		background-color: #bdcfae;
	}

	.title {
		display: block;
		font-family: $fontFutura;
		line-height: 1;
		font-size: 11px;
		max-width: 100%;
		max-height: calc(11px * 1.3 * 2);
		-webkit-line-clamp: 2;
		-moz-line-clamp: 2;
		-webkit-box-orient: vertical;
		font-size: 11px;
		letter-spacing: 0.05em;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
