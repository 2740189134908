.image {
	position: relative;
	width: 100%;
	height: auto;
	overflow: hidden;

	&::after {
		content: '';
		display: inline-block;
		vertical-align: middle;
		width: 0;
		padding-top: 75%;
	}

	& img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&.isLook {
		&::after {
			display: none;
		}

		img {
			position: relative;
		}
	}
}
