@import '@/assets/styles/var.scss';
@import '@/assets/styles/mixins.scss';

.double-gallery-title {
	display: block;
	font-weight: 500;
	font-family: $fontFutura;
	font-size: 32px;
	line-height: 120%;
	text-align: center;
	color: $black-1;

	&:not(:last-child) {
		margin-bottom: 10px;
	}
}

.double-gallery-description {
	font-size: 22px;
	text-align: center;
}

.double-gallery-button {
	&:not(:first-child) {
		margin-top: 32px;
	}
}

.double-gallery-content {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	padding: 40px 20px 0;

	@include maxWidth($lMobile) {
		padding: 24px 0 0;
	}
}
