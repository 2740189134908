@import '@/assets/styles/fonts.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/var.scss';

.header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9998;
	width: 100%;
	flex-shrink: 0;
	color: $black-1;
	background: $white;

	+ * {
		margin-top: 63px;
	}

	&.new-year-bg {
		color: $white;
		background: $black-2;
	}

	@include maxWidth($sDesktop) {
		+ * {
			margin-top: 58px;
		}
	}

	@include maxWidth($mTablet + 1) {
		+ * {
			margin-top: 56px;
		}
	}
}

.banner {
	+ * {
		margin-top: 112px;
	}
}

.bar {
	position: relative;
	z-index: 11;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 63px;

	@include maxWidth($sDesktop) {
		height: 58px;
	}

	@include maxWidth($mTablet + 1) {
		height: 56px;
	}
}

.left {
	height: 100%;
	display: flex;
	align-items: center;
}

.nav-wrapper,
.mobile-nav-toggle {
	height: 100%;
}

.mobile-nav-toggle {
	display: none;

	@include maxWidth($tablet + 1) {
		display: block;
	}
}

.nav-wrapper {
	@include maxWidth($tablet + 1) {
		display: none;
	}
}

.logo {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	align-items: center;
	height: 100%;
	font-family: $fontFutura;
	font-size: 21px;
	line-height: 12px;
	font-weight: 600;
	letter-spacing: 0.05em;
	white-space: nowrap;
	text-transform: uppercase;
	cursor: default;

	&:hover .logo-link {
		opacity: 0.8;
	}

	@include maxWidth($tablet + 1) {
		position: static;
		transform: translateX(0);
		width: calc(100% - 220px);
		font-size: 20px;
	}

	@include maxWidth($lMobile) {
		font-size: 15px;
		width: calc(100% - 160px);
	}

	a {
		transition: opacity 0.3s ease-out;
	}

	.logoImg {
		height: 24px;

		@include maxWidth($tablet + 1) {
			height: 16px;
			width: 51px;
		}
	}
}

.controls {
	display: flex;
	align-items: center;
	height: 100%;
	margin-right: -8px;
}
