@import '@/assets/styles/var.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/fonts.scss';

.container {
	display: flex;
	justify-content: space-between;

	@include maxWidth($mTablet + 1) {
		flex-direction: column-reverse;
	}

	.textBlock {
		max-width: 616px;

		@include maxWidth($tablet + 1) {
			max-width: 550px;
		}

		@include maxWidth($mTablet + 1) {
			max-width: 522px;
		}

		.title {
			color: $black-1;
			font-family: $fontFutura;
			font-size: 32px;
			font-weight: 450;
			line-height: 130%;
			margin-bottom: 16px;

			@include maxWidth($mTablet + 1) {
				font-size: 28px;
				line-height: 120%;
				margin-bottom: 8px;
			}
		}

		.description {
			color: $black-1;
			font-size: 20px;
			font-weight: 400;
			line-height: 130%;
			letter-spacing: 0.1px;

			@include maxWidth($mTablet + 1) {
				font-size: 18px;
			}
		}
	}

	.buttonBlock {
		display: flex;
		justify-content: center;
		width: 100%;

		@include maxWidth($mTablet + 1) {
			margin-bottom: 32px;
		}

		.button {
			width: 232px;

			@include maxWidth($mobile + 1) {
				width: 100%;
			}
		}
	}
}
