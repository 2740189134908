@import '@/assets/styles/var.scss';

@mixin maxWidth($breakpoint) {
	@media only screen and (max-width: $breakpoint) {
		@content;
	}
}

@mixin minWidth($breakpoint) {
	@media only screen and (min-width: $breakpoint) {
		@content;
	}
}

@mixin minMaxWidth($breakpointMin, $breakpointMax) {
	@media only screen and (min-width: $breakpointMin) and (max-width: $breakpointMax) {
		@content;
	}
}

@mixin noTouch() {
	@media not all and (-moz-touch-enabled: 1),
		not all and (pointer: coarse),
		not all and (-ms-high-contrast: none) and (max-width: 600px) {
		@content;
	}
}

@mixin title {
	font-family: $fontFutura;
	font-style: normal;
	font-weight: 450;
	color: $black-1;
}

@mixin scrollBar($barColor: $white, $thumbColor: $gray-1, $radius: 4px, $size: 6px) {
	scrollbar-color: $thumbColor $barColor;
	scrollbar-width: thin;

	&::-webkit-scrollbar {
		background-color: $barColor;
		width: $size;
		height: $size;
		border-radius: $radius;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: $radius;
		background-color: $thumbColor;
	}
}

@keyframes load {
	from {
		transform: translateX(-150px);
	}

	to {
		transform: translateX(calc(100% + 150px));
	}
}

@mixin withLoadingAnimation {
	position: relative;

	&::before {
		content: '';
		display: block;
		position: absolute;
		z-index: 3;
		left: 0;
		top: 0;
		height: 100%;
		width: 150px;
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		background: linear-gradient(
			to right,
			rgba(255, 255, 255, 0) 0%,
			#fff 50%,
			rgba(255, 255, 255, 0) 100%
		);
	}

	&.loading {
		overflow: hidden;
		pointer-events: none;

		&:before {
			opacity: 1;
			visibility: visible;
			animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
			animation-fill-mode: both;
		}
	}
}

@mixin fadeInRollBottom() {
	@keyframes fade {
		0% {
			opacity: 0;
			transform: translateY(20px) translateZ(0);
		}

		100% {
			opacity: 1;
			transform: translateY(0) translateZ(0);
		}
	}

	animation: fade 0.6s;
}
