.shorts {
	width: 100%;

	.video-wrapper {
		width: 100%;
		display: flex;
		justify-content: center;

		iframe {
			min-height: 85vh !important
		}
	}
}
