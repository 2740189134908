@import '@/assets/styles/var.scss';
@import '@/assets/styles/mixins.scss';

.productGallery {
	.swiper-button-prev,
	.swiper-button-next {
		display: none !important;
	}

	.swiper-container-vertical {
		height: 504px;

		@include maxWidth($mTablet) {
			height: 400px;
		}
	}
}
