@import '@/assets/styles/fonts.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/var.scss';
.link {
	&:hover {
		cursor: pointer;
	}
}

.control {
	height: 100%;
	position: relative;
	user-select: none;

	&.disabled {
		cursor: initial;
	}

	.controlTrigger {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		color: $black-1;
		position: relative;
	}

	&User {
		.controlTrigger {
			flex-direction: column;
			padding: 8px;

			.text {
				display: block;
				margin-top: 5px;
				font-size: 12px;
				color: $black-1;

				&.newYear {
					color: $white;
				}

				@include maxWidth($lMobile) {
					display: none;
				}
			}

			svg {
				width: auto;
				height: 16px;
			}
		}
	}

	&Phone {
		.controlTrigger {
			align-items: center;
			cursor: default;
			font-size: 16px;
			padding-right: 12px;

			&:after {
				content: '';
				position: absolute;
				top: calc(50% - 5px);
				right: 0;
				display: block;
				width: 6px;
				height: 6px;
				border-right: 1px solid currentColor;
				border-bottom: 1px solid currentColor;
				transform: rotate(45deg);
				transform-origin: center;
				transition: transform 0.2s ease;
			}
		}

		&:hover {
			.controlTrigger:after {
				transform: translateY(4px) rotate(225deg);
			}
		}

		@include maxWidth($sDesktop) {
			display: none;
		}
	}

	.quantity {
		position: absolute;
		top: 6px;
		left: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: 18px;
		height: 18px;
		padding: 1px 4px 2px;
		font-family: $fontFuturaDemi;
		font-weight: 500;
		font-size: 12px;
		line-height: 1;
		text-transform: uppercase;
		border-radius: 16px;
		background: $green-1;
		color: $white;

		@include maxWidth($sDesktop) {
			top: 18px;
			left: 45%;
		}
	}

	.controlDropdown {
		position: absolute;
		top: calc(100% - 10px);
		left: 50%;
		background: 0 0;
		cursor: default;
		opacity: 0;
		z-index: 15;
		visibility: hidden;
		pointer-events: none;
		transform: translateX(-50%);
		transition: all 0.2s ease-out;

		&.login {
			@include maxWidth($tablet + 1) {
				display: none;
			}
		}
	}

	.controlInner {
		position: relative;
		padding: 24px;
		color: $black-1;
		background: $white;
		border-radius: 18px;
		box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
		transform: translateY(20px);
		transition: transform 0.3s ease-out;

		&::before {
			content: '';
			position: absolute;
			bottom: 100%;
			left: calc(50% - 10px);
			border-bottom: 9px solid $white;
			border-right: 10px solid transparent;
			border-left: 10px solid transparent;
		}
	}

	&:hover {
		.controlDropdown {
			top: calc(100% - 25px);
			opacity: 1;
			visibility: visible;
			pointer-events: all;
		}
	}
}
