@import '@/assets/styles/var.scss';
@import '@/assets/styles/fonts.scss';
@import '@/assets/styles/mixins.scss';

.container {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	pointer-events: none;
	z-index: 9997;
	visibility: hidden;
	opacity: 0;
	cursor: pointer;
	background: rgba($black-1, 0.8);
	transition: visibility 0s 0.25s, opacity 0.25s ease;
	-ms-scroll-chaining: none;
	overscroll-behavior: contain;
	-webkit-overflow-scrolling: touch;
	user-select: none;

	.menu {
		position: relative;
		height: 100%;
		min-width: 250px;
		width: 100%;
		max-width: 500px;
		padding-top: 58px;
		margin-right: auto;
		background: $white;
		opacity: 0;
		overflow: hidden;
		cursor: default;
		transform: translateX(-100%);
		transition: transform 0.25s ease-in, opacity 0.25s linear;

		@include maxWidth($lMobile) {
			padding-left: 20px;
		}
	}

	.menuBanner {
		padding-top: 114px;
	}

	.menuInner {
		position: relative;
		width: 100%;
		height: 100%;
		overflow: hidden;
		background: $white;
	}

	.level {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		color: $black-1;
		background: $white;
		box-shadow: 2px 0px 2px 0px $gray-1;
		overflow: hidden;
		transition: opacity 200ms, transform 200ms;
	}

	.levelInner {
		width: 100%;
		height: 100%;
		padding-left: 32px;
		overflow-x: hidden;
		overflow-y: auto;
		@include scrollBar($gray-2, $gray-1, 2px, 4px);
		-ms-scroll-chaining: none;
		overscroll-behavior: contain;
		-webkit-overflow-scrolling: touch;
		user-select: none;

		@include maxWidth($mTablet + 1) {
			padding-left: 20px;
		}
	}

	.profileList {
		padding-top: 12px;
		padding-bottom: 12px;
	}

	.profileLink {
		display: block;
		width: calc(100% + 32px);
		margin-left: -32px;
		padding: 11px 32px;
		font-size: 20px;
		color: $black-1;
		text-align: left;

		@include maxWidth($mTablet + 1) {
			width: calc(100% + 20px);
			margin-left: -20px;
			padding-left: 20px;
			padding-right: 20px;
		}
	}
}

.visibleContainer {
	@include maxWidth($tablet + 1) {
		opacity: 1;
		visibility: visible;
		pointer-events: all;
		transition: none;
		transition: visibility 0s, opacity 0.25s ease;

		.menu {
			opacity: 1;
			transform: none;
			transition: transform 0.25s ease-out, opacity 0.25s linear;
		}
	}
}
