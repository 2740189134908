@import '@/assets/styles/var.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/fonts.scss';

.specialOffers {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 48px 32px;

	&:not(:last-child) {
		margin-bottom: 48px;
	}

	@include maxWidth($tablet + 1) {
		grid-gap: 48px 24px;
	}

	@include maxWidth($mobile) {
		grid-template-columns: 1fr;
	}
}

.img {
	position: relative;
	width: 100%;
	margin-bottom: 8px;
	transition: opacity 0.3s ease;

	&::after {
		content: '';
		display: inline-block;
		vertical-align: top;
		padding-top: 66.66%;
	}

	& img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&:hover {
		opacity: 0.85;
	}
}

.title {
	@include h2;
	font-weight: 450;
	color: $black-1;

	@include maxWidth($mTablet + 1) {
		@include h2-mobile;
	}
}

.subTitle {
	font-size: 14px;
	line-height: 130%;
	font-weight: 400;
	color: $black-4;
	margin-bottom: 6px;
}
