@import '@/assets/styles/var.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/fonts.scss';

.main {
	display: grid;
	grid-gap: 20px;
	grid-template-columns: repeat(3, minmax(0, 1fr));

	@include maxWidth($tablet + 1) {
		grid-gap: 12px;
	}

	@include maxWidth($mTablet + 1) {
		display: none;
	}
}

.container {
	.slider {
		display: none;

		@include maxWidth($mTablet + 1) {
			display: block;
		}

		.link {
			margin-right: 12px !important;

			&:first-child {
				margin-left: 0px;
			}

			@include maxWidth($mTablet + 1) {
				width: 294px;
			}

			@include maxWidth($mobile + 1) {
				width: 288px;
			}
		}

		.image {
			object-fit: cover;

			@include maxWidth($mTablet + 1) {
				width: 100%;
				height: 392px;
			}

			@include maxWidth($mobile + 1) {
				width: 100%;
				height: 384px;
			}
		}
	}

	.content {
		margin-top: 24px;
		color: $black-1;
		max-width: 800px;
		margin-right: auto;
		margin-left: auto;

		.title {
			font-family: $fontFutura;
			font-size: 40px;
			line-height: 140%;
			font-weight: 450;
			text-align: center;
			color: $black-1;

			@include maxWidth($mTablet + 1) {
				font-size: 28px;
				line-height: 120%;
			}

			@include maxWidth($mobile + 1) {
				text-align: left;
			}
		}

		.desc {
			margin-top: 8px;
			font-size: 20px;
			font-weight: 400;
			line-height: 130%;
			text-align: center;
			color: $black-2;

			@include maxWidth($mTablet + 1) {
				font-size: 18px;
				line-height: 130%;
			}

			@include maxWidth($mobile + 1) {
				text-align: left;
			}
		}

		.button {
			margin-top: 24px;
			margin-left: auto;
			margin-right: auto;
			width: 313px;

			@include maxWidth($mobile + 1) {
				width: 100%;
			}
		}

		.h1-content {
			margin-top: 24px;
		}

		.h2-content {
			margin-top: 16px;
		}

		.h3-content {
			margin-top: 16px;
		}

		.h1-title {
			font-size: 40px;
			line-height: 140%;

			@include maxWidth($mTablet + 1) {
				font-size: 28px;
				line-height: 120%;
			}
		}

		.h2-title {
			font-size: 32px;
			line-height: 140%;

			@include maxWidth($mTablet + 1) {
				font-size: 24px;
				line-height: 120%;
			}
		}

		.h3-title {
			font-size: 28px;
			line-height: 140%;

			@include maxWidth($mTablet + 1) {
				font-size: 20px;
				line-height: 120%;
			}
		}
	}
}

.item {
	position: relative;
}

.image {
	position: relative;
	width: 408px;
	height: 544px;
	overflow: hidden;

	@include maxWidth($xmDesktop + 1) {
		width: 100%;
	}

	@include maxWidth($tablet + 1) {
		width: 312px;
		height: 416px;
	}

	@include maxWidth($tablet) {
		width: 100%;
	}

	&::after {
		content: '';
		display: inline-block;
		vertical-align: middle;
		width: 0;
		padding-top: var(--nav-picture-image-height, 75%);
	}

	& img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.contentSlider {
	margin-top: 16px;

	.title {
		color: $black-1;
		display: block;
		font-weight: 450;
		font-size: 32px;
		line-height: 130%;
		font-family: $fontFutura;
		margin-bottom: 8px;

		@include maxWidth($mTablet + 1) {
			font-size: 24px;
		}
	}

	.desc {
		color: $black-2;
		font-weight: 400;
		font-size: 20px;
		line-height: 130%;

		@include maxWidth($mTablet + 1) {
			font-size: 18px;
		}
	}
}
