@import '@/assets/styles/var.scss';
@import '@/assets/styles/mixins.scss';

.container {
	min-width: 280px;
	width: $containerDefault;
	max-width: $maxContentWidth;
	margin-left: auto;
	margin-right: auto;

	@include maxWidth($tablet + 1) {
		width: $containerTablet;
	}

	@include maxWidth($mTablet + 1) {
		width: $containerMTablet;
	}

	@include maxWidth($lMobile) {
		width: $containerLMobile;
	}
}

.center {
	justify-content: center;
	display: flex;
}
