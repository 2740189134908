@import '@/assets/styles/var.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/fonts.scss';

.share {
	margin-bottom: 144px;
	margin-top: -96px;

	@include maxWidth($mTablet + 1) {
		margin-top: -112px;
	}
}

.shareEmpty {
	margin-top: 0;
}

.bread-crumbs {
	margin-top: 32px;

	&:not(:last-child) {
		margin-bottom: 22px;
	}
}

.blocks {
	margin-top: 16px;
}

.news-blocks {
	margin-top: 8px;
}

.sub-title {
	@include h1;
	margin-bottom: 16px;
	color: $black-1;

	@include maxWidth($mTablet + 1) {
		@include h1-mobile;
	}
}

.type {
	font-weight: 400;
	color: $black-4;
	font-size: 14px;
}

.date {
	display: block;
	font-size: 14px;
	color: $black-4;
	margin-left: 8px;
	margin-top: 0.05%;
}

.text-page-article {
	display: flex;
	margin-bottom: 8px;
}

.articles {
	margin-bottom: 144px;
	border-top: 1px solid $gray-1;
	padding-top: 92px;

	@include maxWidth($mTablet + 1) {
		padding-top: 64px;
	}
}

.articles-title {
	margin-bottom: 48px;
}

.link-button {
	margin-top: -16px;
	width: 247px;

	button {
		width: 100%;
	}

	@include maxWidth($mTablet + 1) {
		display: none;
	}
}
