.react-images__blanket {
    z-index: 9999 !important;
}

.react-images__positioner {
    z-index: 10000 !important;
}

.react-images__view--isModal {
    display: flex;
    justify-content: center;
}

.yarl__thumbnails_thumbnail_active {
    border: 2px solid #cccdd0 !important;
}

.yarl__button {
    filter: none !important;
}

.yarl__fullsize {
    cursor: zoom-in;
}

.yarl__navigation_next {
    padding-right: 32px !important;
}

.yarl__toolbar {
    padding: 32px !important;
    z-index: 1000;
}

@media (max-width: 768px) {
    .yarl__toolbar {
        padding: 8px !important;
    }
}
