@import '@/assets/styles/var.scss';
@import '@/assets/styles/mixins.scss';

.gallery {
	display: grid;
	grid-gap: 40px 32px;
	grid-template-columns: repeat(2, minmax(0, 1fr));

	@include maxWidth($lMobile) {
		grid-template-columns: minmax(0, 1fr);
	}
}
