@import '@/assets/styles/var.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/fonts.scss';

.isProfile {
	margin-left: 8px;
}

.block {
	.title {
		font-weight: 450;
		font-size: 24px;
		line-height: 130%;
		color: $black-1;
		margin-bottom: 48px;
		font-family: $fontFutura;
		text-align: left;

		@include maxWidth($mobile + 1) {
			margin-bottom: 32px;
			font-weight: 450;
			font-size: 20px;
			line-height: 24px;
		}
	}

	.buttonContainer {
		display: grid;
		gap: 32px;
		grid-template-columns: 1fr 1fr;

		@include maxWidth($mTablet + 1) {
			gap: 24px;
		}

		@include maxWidth($mobile + 1) {
			gap: 20px;
		}

		.buttonCancel {
			@include maxWidth($mobile + 1) {
				min-width: 130px;
			}

			@include maxWidth($xsMobile + 1) {
				min-width: 100px;
			}
		}

		.buttonLogout {
			@include maxWidth($mobile + 1) {
				min-width: 130px;
			}

			@include maxWidth($xsMobile + 1) {
				min-width: 100px;
			}
		}
	}
}
