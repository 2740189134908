.react-datepicker__month-container {
    width: 100%;
}

.react-datepicker__header {
    background-color: #0fb769;
    padding: 12px 0;
}

.react-datepicker__navigation {
    margin-top: 8px;
}

.react-datepicker__current-month {
    color: #fff;
    font-size: 16px;
    margin-bottom: 8px;
}

.react-datepicker__day-name {
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    width: 32px;
}

.react-datepicker__day {
    font-size: 14px;
    color: #585858;
    padding: 2px 8px !important;
    width: 32px;
}

.react-datepicker__day:hover {
    color: #fff;
    background-color: #0fb769;
}

.react-datepicker__day--selected,
.react-datepicker__day--today {
    color: #fff;
    background-color: #0fb769;
}

.react-datepicker__day--disabled {
    color: #bbbbbb;
}

.react-datepicker__day--disabled:hover {
    color: #bbbbbb;
    background-color: #fff;
}

.react-datepicker__triangle {
    transform: translate(150px, 0px) !important;
}

.react-datepicker__day--keyboard-selected {
    background-color: #fff;
    color: #171717;
}
