@import '@/assets/styles/var.scss';
@import '@/assets/styles/fonts.scss';
@import '@/assets/styles/mixins.scss';

.item {
	page-break-inside: avoid;
	break-inside: avoid;
}

.listWrap2 {
	position: absolute;
	top: 0;
	left: 216px;
	background: #fff;
	width: calc(100% - 216px);
	height: calc(100vh - 90px);
	z-index: 1;
	padding-right: 8px;

	&.leaved {
		z-index: 2;
	}

	&.visible {
		z-index: 3;
	}
}

.listScroll2 {
	overflow-y: auto;
	width: 100%;
	height: 100%;

	padding: 32px 24px 32px 32px;
	@include scrollBar();
}

.listScroll3 {
	.item {
		&:not(:last-child) {
			margin-bottom: 8px;
		}

		&.hidden {
			display: none;
		}

		&.open {
			display: list-item;
		}
	}
}

.list2 {
	display: grid;
	grid-template-columns: repeat(auto-fit, 220px);
	grid-column-gap: 32px;
	grid-row-gap: 23px;
}

// .banner {
//     grid-area: banner;
//     font-size: 22px;
//     color: #fff;
//     font-family: $fontFutura;
//     font-weight: 500;
//     text-align: center;
//     text-transform: uppercase;
// }

.link {
	display: block;

	&.level1 {
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
		@include header-menu;
		font-weight: 450;
		color: $black-2;
		padding: 8px 12px;

		&.isOpened {
			background-color: #fff;
			color: $black-1;
		}
	}

	&.level2 {
		@include header-menu;
		font-weight: 400;
		color: $black-1;
		margin-bottom: 16px;
	}

	&.level3 {
		font-size: 18px;
		line-height: 140%;
		font-family: inherit;
		color: $black-2;
	}

	&.level2,
	&.level3 {
		&:hover {
			color: $green-2 !important;
		}

		&[active='true'] {
			&:hover {
				color: $gray-1 !important;
			}
		}
	}

	&.premium {
		color: $blue-1;
	}

	&.sale {
		color: $red-1;
	}

	&[active='true'] {
		color: $green-1;
		cursor: default;
	}
}

.btnContainer {
	padding-top: 8px;

	.sectionButton {
		min-width: auto;
	}
}
