@import '@/assets/styles/var.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/fonts.scss';

.button {
	width: 310px;
	margin: 0 auto;

	@include maxWidth($mobile + 1) {
		width: 100%;
	}
}
