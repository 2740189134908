@import '@/assets/styles/var.scss';
@import '@/assets/styles/fonts.scss';

@keyframes slide {
	0% {
		width: 0px;
		left: 10px;
	}
	20% {
		width: 10px;
		left: 10px;
	}
	100% {
		left: 200px;
	}
}

.input {
	position: relative;
	height: 44px;

	svg {
		z-index: 1;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 16px;
		opacity: 0;
	}

	&:hover {
		.tooltip {
			opacity: 1;
			display: block;
		}
	}

	&.loading {
		&::after {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: 4px;
			height: 30px;
			width: 100px;
			opacity: 0;
			visibility: hidden;
			pointer-events: none;
			background: $white;
			opacity: 0.8;
			visibility: visible;
			animation: slide 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
			animation-fill-mode: both;
		}
	}

	&.textarea {
		height: 96px;

		.textAreaInput {
			padding-top: 8px;
		}
	}

	.search {
		opacity: 1;
	}

	.clear {
		opacity: 1;
		right: 16px;
		left: auto;

		&:hover {
			cursor: pointer;
		}
	}

	.label {
		font-size: 14px;
		line-height: 10px;
		position: absolute;
		top: -6px;
		left: 15px;
		padding: 0;
		color: $black-3;
		background-color: #ffffff;
		transition: all 200ms cubic-bezier(0, 0, 0.2, 1);
		transform-origin: 0;

		&.labelIsInside {
			transform: translate(0, 22px) scale(1.42);
		}

		&.labelIsInsideSearch {
			transform: translate(32px, 22px) scale(1.42);
		}
	}

	.field {
		position: relative;
		@include content-book;
		width: 100%;
		height: 100%;
		border: 1px solid $gray-1;
		border-radius: 16px;
		padding-left: 16px;
		transition: all 300ms ease-out;

		&:hover,
		&:focus {
			border: 1px solid $green-2;
		}
	}

	.info-text {
		font-size: 14px;
		line-height: 16px;
		color: $black-4;
		position: absolute;
		bottom: 0;
		left: 15px;
		transform: translateY(100%);
		width: 95%;

		&.error {
			color: $red-1;
		}
	}

	&.read-only {
		.label {
			&.labelIsInside {
				background-color: transparent;
			}
		}

		.field {
			color: $black-3;
			background-color: $gray-2;
		}
	}

	&.disabled {
		.label {
			&.labelIsInside {
				background-color: transparent;
			}
			.field {
				color: $black-4;
			}
		}

		input {
			cursor: default;
			background-color: $gray-2;
		}
	}

	&.error {
		color: $red-1;

		.label {
			// color: $red-1;
		}

		.field {
			border: 1px solid $red-1 !important;
		}
	}

	&.success {
		.field {
			padding-left: 42px;
		}

		svg {
			opacity: 1;
		}
	}

	&.freezed {
		.field {
			background-color: #fff;
		}
	}

	.tooltip {
		top: calc(100% + 16px);
		position: absolute;
		width: 100%;
		padding: 16px;
		z-index: 2;
		display: none;
		opacity: 0;
		background-color: $white;
		border-radius: 4px;
		filter: drop-shadow(0px 2px 25px rgba(0, 0, 0, 0.1));

		.header {
			@include card-price;
			color: $green-1;
			margin-bottom: 4px;
		}

		&::after {
			content: '';
			top: -10px;
			left: calc(50% - 14px);
			transform: rotate(45deg);
			transform-origin: center;
			position: absolute;
			display: block;
			width: 20px;
			height: 20px;
			background-color: $white;
		}

		&:hover {
			opacity: 0;
		}
	}
}

.label {
	position: absolute;
	top: -12px;
	left: 15px;
	padding: 0 4px;
	color: $black-4;
	background-color: #ffffff;
	transition: all 200ms cubic-bezier(0, 0, 0.2, 1);

	&.labelIsInside {
		transform: translate(0, 24px) scale(1.3);
	}
}

.labelError {
	color: $red-1 !important;
}
