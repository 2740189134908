@import '@/assets/styles/var.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/fonts.scss';

.select {
	position: relative;
	display: inline-block;
}

.head {
	position: relative;
	padding: 6px 20px 6px 0;
	font-size: 16px;
	color: $black-1;
	user-select: none;
	cursor: pointer;
	text-align: right;
	transition: color 0.3s ease;

	&::after {
		content: '';
		position: absolute;
		top: 11px;
		right: 6px;
		display: block;
		width: 7px;
		height: 7px;
		border-bottom: 1px solid $black-1;
		border-right: 1px solid $black-1;
		background: 0 0;
		transform-origin: 50% 50%;
		transform: rotate(45deg);
		transition: transform 0.3s ease-out;
	}
}

.headHover {
	&:hover {
		color: $black-2;
	}
}

.selected {
	font-family: $fontFutura;
	font-weight: 500;
	color: $green-1;
	&::after {
		opacity: 0;
		visibility: hidden;
	}

	&:hover {
		color: #43a084;
	}
}

.open {
	&::after {
		transform: translateY(4px) rotate(225deg);
	}
}

.disabled {
	padding-right: 0px;
	color: $black-4;
	cursor: default;

	&::after {
		content: none;
	}
}

.clear {
	opacity: 0;
	position: absolute;
	top: 9px;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 18px;
	height: 20px;
	pointer-events: none;
	transition: transform 0.2s ease;

	&::before,
	&::after {
		content: '';
		position: absolute;
		width: 12px;
		height: 1px;
		background: $green-1;
	}

	&::before {
		transform: rotate(-45deg);
	}

	&::after {
		transform: rotate(45deg);
	}

	&:hover {
		transform: scale(1.1);
	}
}

.visibleClear {
	opacity: 1;
	pointer-events: all;
}

.body {
	position: absolute;
	top: calc(100% + 16px);
	left: calc(50% - 91px);
	z-index: 3;
	width: 182px;
	background: $white;
	box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
	transform: translateY(20px);
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	overflow: hidden;
	will-change: transform;
	transition: visibility 0s 0.3s, opacity 0.3s ease-out, transform 0.3s ease-out,
		border-color 0.3s ease;
	border-radius: 16px;

	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 50%;
		z-index: -1;
		display: block;
		width: 25px;
		height: 25px;
		background: $white;
		transform: translate(-50%, -26%) rotate(45deg);
	}
}

.visibleBody {
	opacity: 1;
	transform: translateY(0);
	visibility: visible;
	pointer-events: all;
	transition: visibility 0s, opacity 0.3s ease-out, transform 0.3s ease-out, border-color 0.3s ease;
}

.content {
	position: relative;
	top: 0;
	max-height: 205px;
	margin: 16px;
	overflow-y: auto;
	scrollbar-color: $gray-1 $white;
	scrollbar-width: thin;

	&::-webkit-scrollbar {
		background-color: $white;
		width: 4px;
		border-radius: 2px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $gray-1;
		border-radius: 2px;
	}
}
