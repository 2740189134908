@import '@/assets/styles/var.scss';
@import '@/assets/styles/mixins.scss';

.block {
	--html-text-size: 20px;
	margin-bottom: 48px;

	@include maxWidth($mTablet + 1) {
		--html-text-size: 18px;
	}
}

.noMargin {
	&:not(:first-child) {
		margin-top: 0;
	}
}

.shops-on-map {
	--shops-map-height: 900px;
	--shops-map-side-border: $gray-1;

	@include maxWidth($tablet) {
		--shops-map-height: 600px;
	}

	@include maxWidth($tablet) {
		--shops-map-height: calc(100vh - 56px);
	}
}
