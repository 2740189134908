@import '@/assets/styles/mixins.scss';

.products-slider {
	&:not(:first-child) {
		margin-top: 48px;

		@include maxWidth($mTablet + 1) {
			margin-top: 32px;
		}

		@include maxWidth($mobile) {
			margin-top: 24px;
		}
	}

	&:not(:last-child) {
		margin-bottom: 32px;
	}
}

.products-slider-link {
	display: flex;
	justify-content: center;
	--button-m-horizontal: 0;
	justify-content: flex-start;
}
