@import '@/assets/styles/mixins.scss';

.content-limiter {
	max-width: 832px;
	margin: 0 auto;
}

.link {
	display: flex;
	justify-content: center;
	--button-m-horizontal: 0;
}
