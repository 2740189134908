@import '@/assets/styles/var.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/fonts.scss';

.center {
	text-align: center;
}

.left {
	text-align: left;
}

.right {
	text-align: right;
}

.title {
	color: $black-1;
	margin-bottom: 8px;

	@include maxWidth($mobile + 1) {
		text-align: left;
	}
}

.h1 {
	@include h1;

	@include maxWidth($mTablet + 1) {
		@include h1-mobile;
	}
}

.h2 {
	@include h2;

	@include maxWidth($mTablet + 1) {
		@include h2-mobile;
	}
}

.h3 {
	@include h3;

	@include maxWidth($mTablet + 1) {
		@include h3-mobile;
	}
}

.container {
	max-width: 800px;

	@include maxWidth($tablet + 1) {
		max-width: 700px;
	}

	@include maxWidth($mTablet + 1) {
		max-width: 596px;
	}

	@include maxWidth(700px) {
		max-width: 100%;
	}
}

.link {
	.title {
		&:hover {
			color: $black-3 !important;
		}
	}
}
