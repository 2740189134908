@import '@/assets/styles/var.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/fonts.scss';

.title {
	color: $black-1;
	font-weight: 450;
	font-size: 32px;
	line-height: 140%;
	text-align: center;
	font-family: $fontFutura;

	@include maxWidth($mTablet + 1) {
		font-size: 24px;
		line-height: 120%;
	}

	@include maxWidth($mobile + 1) {
		text-align: left;
	}
}

.carouselProductsSlider {
	&:not(:first-child) {
		margin-top: 24px;
	}

	&:not(:last-child) {
		margin-bottom: 32px;
	}

	.link {
		width: 224px !important;
		height: 336px;

		@include maxWidth($tablet + 1) {
			margin-right: 24px !important;
			width: 206px !important;
			height: 309px;
		}

		@include maxWidth($mTablet + 1) {
			width: 190px !important;
			height: 285px;
		}

		@include maxWidth($mobile + 1) {
			margin-right: 16px !important;
			width: 130px !important;
			height: 195px;
		}

		& span {
			position: static !important;
		}

		& img {
			object-fit: cover;
			height: 336px !important;
			position: static !important;

			@include maxWidth($tablet + 1) {
				height: 309px !important;
			}

			@include maxWidth($mTablet + 1) {
				height: 285px !important;
			}

			@include maxWidth($mobile + 1) {
				height: 195px !important;
			}
		}
	}

	.linkWithSubtitle {
		height: auto;
		// height: 375px;

		// @include maxWidth($tablet + 1) {
		//     height: 348px;
		// }

		// @include maxWidth($mTablet + 1) {
		//     height: 317px;
		// }

		// @include maxWidth($mobile + 1) {
		//     height: 250px;
		// }

		.subtitle {
			color: $black-1;
			margin-top: 8px;
			font-size: 24px;
			font-weight: 450;
			line-height: 130%;
			text-align: center;
			font-family: $fontFutura;

			@include maxWidth($mTablet + 1) {
				font-size: 20px;
				line-height: 120%;
			}
		}
	}

	.notActiveLink {
		pointer-events: none;
		cursor: default;
	}

	.bigSliderLink {
		// width: 616px !important;
		height: 462px;

		@include maxWidth($tablet + 1) {
			// margin-right: 24px !important;
			width: 468px !important;
			height: 351px;

			&:first-child {
				margin-left: 0 !important;
			}
			&:last-child {
				margin-right: 0 !important;
			}
		}

		@include maxWidth($mTablet + 1) {
			width: 340px !important;
			height: 255px;
		}

		@include maxWidth($mobile + 1) {
			// margin: 0 20px !important;
			width: 100% !important;
			height: 262px;

			// &:first-child {
			//     margin-left: 20px !important;
			// }
			// &:last-child {
			//     margin-right: 10px !important;
			// }
		}

		@include maxWidth(412px) {
			height: 251px;
		}

		& img {
			object-fit: cover;
			height: 100%;
			width: 100%;

			// @include maxWidth($tablet + 1) {
			//     height: 309px;
			// }

			// @include maxWidth($mTablet + 1) {
			//     height: 285px;
			// }

			// @include maxWidth($mobile + 1) {
			//     height: 210px;
			// }
		}
	}
}

.carouselProductsBigSlider {
	@include maxWidth($tablet + 1) {
		width: 93.75%;
		margin-left: auto;
		margin-right: auto;
	}

	@include maxWidth($mTablet + 1) {
		width: 91.66%;
	}

	@include maxWidth(650px) {
		width: 89.3%;
	}
}
