@import '@/assets/styles/var.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/fonts.scss';

.mainBlock {
	margin-bottom: 32px;
}

.loader {
	min-height: 306px;
}

.main {
	display: grid;
	grid-gap: 32px;
	grid-template-columns: minmax(0, 2fr) minmax(0, 2fr);
	min-height: 306px;

	@include maxWidth($mTablet + 1) {
		grid-gap: 24px;
	}

	@include maxWidth($mTablet) {
		grid-template-columns: minmax(0, 1fr);
	}

	@include maxWidth($mobile) {
		grid-gap: 16px;
	}

	.mainText {
		display: flex;
		padding: 27px 52px;
		align-items: center;

		@include maxWidth($mTablet + 1) {
			padding: 25px 0;
		}

		@include maxWidth($mTablet) {
			order: 1;
		}
	}

	.textBlock {
		width: 100%;

		@include maxWidth($tablet) {
			padding-left: 16px;
			padding-right: 16px;
		}

		@include maxWidth($mTablet) {
			padding-left: 0;
			padding-right: 0;
		}

		.title {
			margin-bottom: 8px;
			text-align: center;
			color: $black-1;
			@include h1;
			line-height: 48px;

			@include maxWidth($mTablet + 1) {
				@include h1-mobile;
			}
		}

		.text {
			max-width: 500px;
			margin-bottom: 24px;

			@include maxWidth($tablet + 1) {
				max-width: 364px;
			}

			div {
				text-align: center;
				color: $black-2;
				font-size: 20px;
				font-style: normal;
				font-weight: 400;
				line-height: 130%;

				@include maxWidth($mTablet + 1) {
					font-size: 18px;
				}
			}
		}

		.buttonBlock {
			display: flex;
			justify-content: center;
			max-height: 44px;

			.button {
				width: 320px;
				height: 100%;

				@include maxWidth($mTablet + 1) {
					width: 100%;
				}

				@include maxWidth($mobile + 1) {
					width: 87%;
				}
			}
		}
	}

	.imageBlock {
		display: flex;
		align-items: center;
		@include maxWidth($mTablet) {
			order: 0;
		}

		@include maxWidth($mobile) {
			display: none;
		}

		img {
			height: 320px;
			width: 100%;
			object-fit: cover;

			@include maxWidth($mTablet + 1) {
				order: 0;
			}

			@include maxWidth($mobile + 1) {
				height: 200px;
			}
		}
	}

	.imageBlockVisible {
		display: block;
	}
}
