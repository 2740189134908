@import '@/assets/styles/var.scss';
@import '@/assets/styles/mixins.scss';

.list {
	font-size: 14px;
	cursor: default;
}

.item {
	display: inline;

	&::after {
		content: '';
		display: inline-block;
		vertical-align: middle;
		width: 12px;
		height: 12px;
		margin-right: 4px;
		margin-left: 4px;
		color: currentColor;
		background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.869 2.625a.8.8 0 00-1.132 1.131l2.546 2.547-2.546 2.546A.8.8 0 104.87 9.98l3.11-3.111a.798.798 0 000-1.133L4.87 2.625z' fill='%23CCCDD0'/%3E%3C/svg%3E");
	}

	&:last-child {
		pointer-events: none;

		&::after {
			content: none;
		}
	}
}

.link {
	display: inline;
	line-height: 1.5;
	padding-top: 5px;
	padding-bottom: 5px;
	color: $black-2;
	transition: color 0.25s ease-in;

	&.last {
		color: $black-4;
	}

	&:hover {
		color: $black-1;
	}
}
