@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/var.scss';
@import '@/assets/styles/fonts.scss';

.image {
	@include maxWidth($tablet + 1) {
		width: 386px !important;
	}

	@include maxWidth($mobile) {
		width: 100% !important;
	}
}

.gallery {
	position: relative;
	display: flex;
	width: 100%;
	padding-left: 108px;

	@include maxWidth($tablet + 1) {
		padding-left: 82px;
	}

	@include maxWidth($mTablet + 1) {
		padding-left: 159px;
	}

	@include maxWidth($lMobile + 1) {
		padding-left: 0;
	}

	.thumbs {
		position: absolute;
		top: 0;
		left: 0;
		flex-shrink: 0;
		width: 76px;
		padding: 40px 0 40px 0;

		@include maxWidth($tablet + 1) {
			width: 58px;
		}

		@include maxWidth($lMobile + 1) {
			display: none;
		}

		.thumb {
			position: relative;
			width: 100%;
			height: auto;
			cursor: pointer;
			overflow: hidden;
			transition: opacity 0.2s ease-out;
			margin-bottom: 16px;

			&::after {
				content: '';
				display: block;
				width: 100%;
				height: auto;
				padding-top: 150%;
				border-radius: inherit;
			}

			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				z-index: 2;
				display: block;
				width: 100%;
				height: 100%;
				border-radius: inherit;
				transition: box-shadow 0.2s ease-in;
			}

			& img {
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.thumbIsVideo {
			&::before {
				box-shadow: inset 0 0 0 1px $gray-1;
			}
		}

		.thumbActive {
			border-radius: 4px;

			&::before {
				box-shadow: inset 0 0 0 2px #cccdd0;
				transition: box-shadow 0.2s ease-out;
			}
		}

		.thumbNotActive {
			&:hover {
				&::before {
					box-shadow: inset 0 0 0 1px $gray-1;
				}
			}
		}

		.thumbVideo {
			position: absolute;
			top: 0;
			left: 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			font-size: 14px;
			color: $black-1;

			& > svg {
				margin-bottom: 3px;
			}

			@include maxWidth($tablet + 1) {
				font-size: 13px;
			}
		}
	}
}

.galleryIcon {
	&:hover {
		border-radius: 50%;
		background-color: #f5f5f5;
	}
}

.thumbNavBtn {
	display: block;
	position: absolute;
	left: 0;
	width: 100%;
	height: 40px;
	color: $black-1;
	opacity: 0.8;
	transition: opacity 0.2s ease;

	& > svg {
		pointer-events: none;
		transform: none;
		transition: transform 0.2s ease-out;
	}

	&:hover:not(:disabled) {
		opacity: 1;
	}

	&:disabled {
		opacity: 0.2;
		cursor: auto;
	}
}

.thumbsPrev {
	top: 0;

	svg {
		transform: translateY(-5px) rotate(90deg);
	}

	&:hover:not(:disabled) {
		svg {
			transform: translateY(-8px) rotate(90deg);
		}
	}
}

.thumbsNext {
	bottom: 0;

	svg {
		transform: translateY(5px) rotate(-90deg);
	}

	&:hover:not(:disabled) {
		svg {
			transform: translateY(8px) rotate(-90deg);
		}
	}
}

.galleryMain {
	flex-shrink: 0;
	width: 100%;

	.swiperButtonPrev,
	.swiperButtonNext {
		display: none;
	}

	.swiperPagination {
		display: none;

		@include maxWidth($lMobile + 1) {
			display: block;
		}
	}

	.galleryImg {
		position: relative;
		width: 100%;
		height: auto;
		overflow: hidden;
		cursor: zoom-in;
		user-select: none;

		@include maxWidth($tablet + 1) {
			width: 100% !important;
			height: 579px;
		}

		@include maxWidth($lMobile + 1) {
			height: 503px;
		}

		&::after {
			content: '';
			display: inline-block;
			vertical-align: top;
			width: 0;
			height: auto;
			padding-top: 150%;
		}

		img,
		video {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			width: 100%;
			height: 100%;
			object-fit: cover;

			@include maxWidth($mTablet + 1) {
				object-fit: contain;
			}
		}

		.patternImage {
			object-fit: contain;
			cursor: auto;
		}

		video {
			object-fit: contain;
		}
	}

	.blockVideo {
		cursor: auto;
	}
}
