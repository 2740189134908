@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/var.scss';
@import '@/assets/styles/fonts.scss';

@mixin labelFont {
	font-family: $fontFuturaBook;
	font-size: 22px;
	line-height: 130%;

	@include maxWidth($mTablet + 1) {
		font-size: 18px;
	}

	@include maxWidth($sMobile) {
		font-size: 14px;
	}
}

.lookImageLabelsBlock {
	position: relative;
	overflow: hidden;
}

.lookImagePictureHtml {
	display: block;
	align-items: center;
}

.lookImageLabel {
	position: absolute;
	display: flex;
	background: $black-1;
	color: #ffffff;
	opacity: 0.9;
	border-radius: 4px;
	@include labelFont;
	padding: 4px 8px;
	cursor: pointer;
	transform: translateY(50%);

	@include maxWidth($mTablet + 1) {
		font-size: 22px;
		line-height: 130%;
	}

	@include maxWidth($sMobile) {
		font-size: 14px;
	}

	& > span {
		order: 1;
	}

	&.left {
		img {
			transform: rotate(180deg);
			order: 0;
			margin-right: 8px;
		}
	}

	&.right {
		transform: translate(-100%, 50%);
		img {
			order: 2;
			margin-left: 8px;
		}
	}
}

.text {
	margin-right: 2px;
}

.lookImageButton {
	width: 228px;
	position: absolute;
	@include labelFont;
	padding: 4px 8px;
	height: 38px;
	display: flex;

	.content {
		display: flex;
	}

	left: 48px;
	bottom: 48px;

	@include maxWidth($mTablet + 1) {
		left: 32px;
		bottom: 32px;
	}

	@include maxWidth($sMobile) {
		left: 12px;
		bottom: 15px;
	}
}
