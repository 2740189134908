@import '@/assets/styles/var.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/fonts.scss';

.breadCrumbs {
	margin-top: 32px;
	margin-bottom: 22px;
}

.wishlistSection {
	padding-bottom: 144px;
}

.wishlistPagination {
	margin-top: 48px;
}

.wishlistContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 400px;
}

.emptyText {
	text-align: center;
}
