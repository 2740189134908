@import '@/assets/styles/var.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/fonts.scss';

@keyframes rotate {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.loader {
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 2;
	display: none;
	justify-content: center;
	width: 100%;
	height: 100%;
	color: $green-1;
	background: rgba($white, 0.8);
	opacity: 1;
	visibility: visible;
	pointer-events: all;
	transition: opacity 0.3s, visibility 0s;

	svg {
		position: absolute;
		top: 0px;
		transform: translateY(-50%);
		animation: rotate 1s linear infinite;
	}
}

.visible {
	display: flex;
}
