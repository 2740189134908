@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/var.scss';
@import '@/assets/styles/fonts.scss';

.container {
	display: flex;
	margin: 0 auto;
	overflow: visible;
	position: relative;

	@include maxWidth($mTablet + 1) {
		flex-direction: column;
	}

	.imageBlock {
		width: 292px;
		height: 556px;
		position: relative;
		margin-right: 32px;
		overflow: hidden;

		@include maxWidth($sDesktop) {
			height: 496px;
		}

		@include maxWidth($tablet + 1) {
			height: 437px;
			width: 240px;
			margin-right: 24px;
		}

		@include maxWidth($mTablet + 1) {
			width: 340px;
			height: 484px;
			overflow: visible;
			left: 50%;
			transform: translate(-50%, 0%);
			margin-right: 0px;
			margin-bottom: 28px;
		}

		@include maxWidth($mobile + 1) {
			width: 335px;
			height: 484px;
			overflow: visible;
			left: 50%;
			transform: translate(-50%, 0%);
			margin-right: 0px;
			margin-bottom: 28px;
		}

		// @include maxWidth($sMobile){

		// }

		& > svg {
			position: absolute;
			bottom: 0;
			transform: translateY(calc(100% + 4px));

			@include minWidth($mTablet + 1) {
				display: none;
			}
		}

		img {
			height: 100%;
			width: 100%;
			object-fit: cover;

			@include maxWidth($mTablet + 1) {
				object-fit: contain;
			}
		}
	}

	.rightSideImage {
		margin-left: 32px;
		margin-right: 0;

		@include maxWidth($tablet + 1) {
			margin-left: 24px;
		}

		@include maxWidth($mTablet + 1) {
			margin-left: 0px;
		}
	}

	.lookSlider {
		width: calc(100% - 323px);
		flex: 0 0 auto;
		position: relative;

		@include maxWidth($tablet + 1) {
			width: calc(100% - 286px);
		}

		@include maxWidth($mTablet + 1) {
			width: 111%;
			position: relative;
			left: -6%;
			margin-top: 16px;
		}

		.cart {
			@include maxWidth($mTablet + 1) {
				width: 206px;
			}

			@include maxWidth($mobile + 1) {
				width: 158px;
			}
		}
	}

	.lookSliderProductPage {
		@include maxWidth($mTablet + 1) {
			margin-top: 0;
		}
	}
}

.reverseContainer {
	flex-direction: row-reverse;

	@include maxWidth($mTablet + 1) {
		flex-direction: column;
	}
}

.button {
	width: 315px;
	margin-top: 48px;
	padding-right: 70px;
	padding-left: 70px;
	left: 50%;
	transform: translateX(-50%);

	@include maxWidth($mTablet + 1) {
		margin-top: 24px;
	}

	@include maxWidth($sMobile) {
		width: 100%;
	}

	@include maxWidth($xsMobile) {
		font-size: 16px;
	}
}

.collectionTitle {
	margin-bottom: 48px;

	@include maxWidth($mTablet + 1) {
		margin-bottom: 32px;
	}

	@include maxWidth($mobile) {
		margin-bottom: 24px;
	}
}
