@import '@/assets/styles/var.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/fonts.scss';

.error {
	display: flex;
	flex-direction: column;
	height: 100vh;
	min-width: 320px;
}

.container {
	max-width: 700px;
	padding-top: 64px;
	padding-bottom: 144px;
	text-align: center;
}

.title {
	margin-bottom: 32px;
}

.text {
	font-size: 18px;
	margin-bottom: 40px;
	text-align: center;
}

.button {
	margin: 0 auto;

	@include maxWidth($mobile + 1) {
		width: 100%;
	}
}

.logo {
	margin: 64px auto 8px auto;
	cursor: default;

	&:hover .logo-link {
		opacity: 0.8;
	}

	@include maxWidth($lMobile) {
		margin-top: 85px;
	}

	a {
		transition: opacity 0.3s ease-out;
	}

	.logoImg {
		height: 30px;

		@include maxWidth($mTablet + 1) {
			height: 25px;
		}
	}
}

.number {
	font-weight: 450;
	font-size: 128px;
	line-height: 80%;
	font-family: $fontFutura;
	color: $gray-1;
	margin-bottom: 32px;

	@include maxWidth($mTablet + 1) {
		font-size: 110px;
	}
}

.title500 {
	@include h2;
	margin-bottom: 12px;

	@include maxWidth($mTablet + 1) {
		@include h2-mobile;
	}
}

.text500 {
	font-weight: 400;
	font-size: 18px;
	line-height: 130%;
	color: $black-2;
	margin-bottom: 48px;

	@include maxWidth($mTablet + 1) {
		font-size: 16px;
	}
}

.button500 {
	margin: 0 auto;
	width: 301px;

	@include maxWidth($mobile + 1) {
		width: 100%;
	}
}

.link {
	margin-top: 18px;
	color: $green-1;
	font-weight: 400;
	font-size: 18px;
	line-height: 120%;
	font-family: $fontFutura;
	display: block;

	&:hover {
		cursor: pointer;
	}

	@include maxWidth($mTablet + 1) {
		margin-top: 16px;
	}
}
