@import '@/assets/styles/var.scss';
@import '@/assets/styles/fonts.scss';

.country-select-dropdown {
	position: absolute;
	left: 0;
	top: calc(100% - 0px);
	width: 250px;
	padding: 0 20px 20px 20px;
	margin-left: -20px;
	transform: translate3d(0, 10px, 0);
	overflow: hidden;
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	transition: opacity 0.16s, visibility 0s 0.16s, transform 0.16s;
	transition-timing-function: ease;
	z-index: 101;

	.country-select-list {
		border-radius: 18px;
		background: $white;
		padding: 8px 0;
		box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
	}

	&.visible {
		transform: translate3d(0, 0, 0);
		opacity: 1;
		visibility: visible;
		pointer-events: all;
		transition: opacity 0.16s, visibility 0s, transform 0.16s;
	}
}

.country-modal-dialog {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	background: $white;
	margin-top: 58px;
}

.smartBanner {
	margin-top: 114px;
}

.modal-header {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-shrink: 0;
	width: 100%;
	height: 56px;
	padding: 0 20px;

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 20px;
		display: block;
		width: calc(100% - 40px);
		height: 1px;
		background: $gray-1;
	}
}

.modal-close {
	flex-shrink: 0;
	width: 55px;
	height: 100%;
	margin-right: -19px;
	background-position: 50% 50%;
	background-image: url("data:image/svg+xml,%3Csvg width='13' height='14' viewBox='0 0 13 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.932 1.568l10.864 10.864m-10.864 0L11.796 1.568' stroke='%23292C33' stroke-width='2'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
}

.modal-inner {
	height: 100%;
	overflow-y: auto;
}

.country-list {
	height: 100%;
}
