@import '@/assets/styles/var.scss';

.control-link {
	display: block;
	width: 100%;
	padding: 6px 0;
	font-size: 18px;
	text-align: left;
	color: $black-1;
	white-space: nowrap;
	transition: color 0.3s ease;

	&:hover {
		color: $green-1;
	}

	&[active='true'] {
		color: $green-1;
	}
}
