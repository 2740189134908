@import '@/assets/styles/var.scss';
@import '@/assets/styles/fonts.scss';

.wrapper {
	font-size: 16px;

	&.mobile {
		padding: 32px 0;

		.work-time {
			margin-bottom: 16px;
		}

		.content {
			padding-top: 32px;

			&:not(:last-child) {
				padding-bottom: 32px;
			}
		}

		.footer {
			padding-top: 32px;
		}

		.app-links {
			display: flex;
			align-items: center;

			&-item {
				&:not(:last-child) {
					margin-bottom: 0;
					margin-right: 16px;
				}

				a {
					padding: 5.02px 11.73px 7.53px 11.73px;
					font-family: $fontFutura;
					font-weight: 450;
					font-size: 11.7324px;
					line-height: 120%;
					border-radius: 6.70423px;
					min-width: 119px;
					min-height: 41px;

					svg {
						margin-right: 10px;
						width: 18.5px;
						height: 21.5px;
					}
				}
			}
		}
	}
}

.header {
	white-space: pre-wrap;

	&:not(:last-child) {
		border-bottom: 1px solid $gray-1;
	}
}

.phone {
	display: block;
	white-space: nowrap;
	font-family: $fontFutura;
	font-weight: 450;
	font-size: 20px;
	line-height: 130%;
	letter-spacing: 0.005em;
	color: $black-1;

	&:hover {
		color: $black-2;
	}
}

.work-time {
	margin-bottom: 24px;
	@include footer-menu-2;
	color: $black-3;
	letter-spacing: 0.005em;
}

.content {
	padding-top: 24px;

	&:not(:last-child) {
		padding-bottom: 24px;
		border-bottom: 1px solid $gray-1;
	}

	.link {
		display: block;
		@include footer-menu-2;
		color: $black-1;

		&:not(:last-child) {
			margin-bottom: 8px;
		}

		&:hover {
			color: $green-1;
		}

		&[active='true'] {
			color: $gray-1;
			cursor: default;
		}
	}
}

.footer {
	padding-top: 24px;
}

.app-links {
	&-item {
		&:not(:last-child) {
			margin-bottom: 16px;
		}

		a {
			display: flex;
			align-items: center;
			padding: 9px 16px 10px 16px;
			font-family: $fontFutura;
			font-weight: 450;
			font-size: 16px;
			line-height: 120%;
			color: #fff;
			border-radius: 12px;
			background: $black-1;
			transition: background 0.23s ease;

			svg {
				margin-right: 16px;
				width: 26px;
				height: 31px;
			}

			&:hover {
				background: $black-2;
			}
		}
	}
}
