@import '@/assets/styles/mixins.scss';

.main {
	display: grid;
	grid-gap: 40px 32px;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	align-items: center;

	@include maxWidth($lMobile) {
		grid-template-columns: minmax(0, 1fr);
		grid-gap: 24px 32px;
	}
}

.content {
	@include minWidth($lMobile + 1) {
		order: -1;
	}
}
