@import '@/assets/styles/var.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/fonts.scss';

.main {
	display: grid;
	grid-gap: 32px;
	grid-template-columns: repeat(2, minmax(0, 1fr));

	@include maxWidth($tablet + 1) {
		grid-gap: 24px;
	}

	@include maxWidth($mobile + 1) {
		grid-template-columns: minmax(0, 1fr);
	}
}

.item {
	position: relative;

	&:only-child {
		grid-column: 1 / -1;

		--nav-picture-image-height: 50%;
		--nav-picture-content-pb: 48px;
		--nav-picture-title-size: 40px;
		--nav-picture-title-mb: 16px;
		@include maxWidth($lMobile) {
			--nav-picture-title-size: 32px;
		}
	}
}

.image {
	position: relative;
	width: 616px;
	height: 462px;
	overflow: hidden;

	@include maxWidth($xmDesktop + 1) {
		width: 100%;
	}

	@include maxWidth($tablet + 1) {
		width: 468px;
		height: 351px;
	}

	@include maxWidth($tablet) {
		width: 100%;
	}

	@include maxWidth($mTablet + 1) {
		width: 340px;
		height: 255px;
	}

	@include maxWidth($mTablet) {
		width: 100%;
	}

	@include maxWidth($mobile + 1) {
		width: 100%;
		margin: auto;
		height: auto;
	}

	@include maxWidth(374px) {
		width: 100%;
		height: auto;
	}

	&::after {
		content: '';
		display: inline-block;
		vertical-align: middle;
		width: 0;
		padding-top: var(--nav-picture-image-height, 75%);
	}

	& img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.content {
	color: $black-1;
	margin-top: 16px;

	.title {
		display: block;
		font-weight: 450;
		font-size: 32px;
		line-height: 130%;
		font-family: $fontFutura;
		margin-bottom: 8px;

		@include maxWidth($mTablet + 1) {
			font-size: 24px;
		}
	}

	.desc {
		font-weight: 400;
		font-size: 20px;
		line-height: 130%;

		@include maxWidth($mTablet + 1) {
			font-size: 18px;
		}
	}
}
