@import '../../../assets/styles/fonts.scss';
@import '../../../assets/styles/mixins.scss';
@import '../../../assets/styles/var.scss';

.wrapper {
	margin-bottom: 32px;
}

.label {
	line-height: 130%;
	letter-spacing: -0.01em;
	color: $black-1;

	@include maxWidth($sDesktop) {
		letter-spacing: normal;
	}
}

.subscribe-form {
	position: relative;
	width: 100%;
	max-width: 335px;
	border-bottom: 1px solid $black-1;
}

.input {
	width: 100%;
	padding: 0 15px 6px 0;
	font-size: 14px;
	background: 0 0;
}

.subscribe-btn {
	position: absolute;
	bottom: 0;
	right: -9px;
	width: 24px;
	height: 100%;
	color: $black-2;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: opacity 0.3s ease;

	&:hover {
		opacity: 0.5;
	}
}

.text {
	position: absolute;
	top: calc(100% + 3px);
	left: 0;
	width: 100%;
	font-family: $fontFutura;
	font-weight: 500;
	font-size: 14px;

	&.submit-success {
		color: $green-1;
		padding-left: 22px;

		&:before {
			content: '';
			position: absolute;
			left: 2px;
			top: 5px;
			width: 13px;
			height: 6px;
			border-left: 2px solid currentColor;
			border-bottom: 2px solid currentColor;
			transform: rotate(-45deg);
		}
	}

	&.submit-error {
		color: $red-1;
		padding-left: 20px;

		&:before,
		&:after {
			content: '';
			position: absolute;
			left: 2px;
			top: 9px;
			width: 11px;
			height: 2px;
			background: currentColor;
		}

		&:before {
			transform: rotate(-45deg);
		}

		&:after {
			transform: rotate(45deg);
		}
	}
}
