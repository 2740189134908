@import '@/assets/styles/mixins.scss';

.fp-products-slider {
	&:not(:first-child) {
		margin-top: 48px;

		@include maxWidth($mTablet) {
			margin-top: 32px;
		}

		@include maxWidth($mTablet) {
			margin-top: 24px;
		}
	}

	&:not(:last-child) {
		margin-bottom: 32px;
	}
}
