@import './var.scss';

@mixin h1 {
	font-family: $fontFutura;
	font-weight: 450;
	font-size: 40px;
	line-height: 140%;
}

@mixin h1-mobile {
	font-family: $fontFutura;
	font-size: 28px;
	line-height: 120%;
}

@mixin h2 {
	font-family: $fontFutura;
	font-weight: 500;
	font-size: 32px;
	line-height: 140%;
}

@mixin h2-mobile {
	font-family: $fontFutura;
	font-size: 24px;
	line-height: 120%;
}

@mixin h3 {
	font-family: $fontFutura;
	font-weight: 500;
	font-size: 24px;
	line-height: 130%;
}

@mixin h3-mobile {
	font-family: $fontFutura;
	font-size: 20px;
	line-height: 120%;
}

@mixin h4 {
	font-family: $fontFutura;
	font-size: 24px;
	line-height: 130%;
}

@mixin h4-mobile {
	font-family: $fontFutura;
	font-size: 20px;
	line-height: 130%;
}

@mixin subtitle {
	font-family: $fontFuturaBook;
	font-size: 24px;
	line-height: 130%;
}

@mixin subtitle-mobile {
	font-family: $fontFuturaBook;
	font-size: 20px;
	line-height: 120%;
}

@mixin card-brand {
	font-family: $fontFuturaBook;
	font-size: 18px;
	line-height: 120%;
}

@mixin card-brand-mobile {
	font-family: $fontFuturaBook;
	font-size: 16px;
	line-height: 130%;
}

@mixin card-text {
	font-family: $fontFuturaBook;
	font-size: 16px;
	line-height: 130%;
}

@mixin card-text-mobile {
	font-family: $fontFuturaBook;
	font-size: 14px;
	line-height: 130%;
}

@mixin card-price {
	font-family: $fontFutura;
	font-size: 16px;
	line-height: 130%;
}

@mixin card-price-mobile {
	font-family: $fontFutura;
	font-size: 14px;
	line-height: 130%;
}

@mixin card-price-strikethrough {
	font-family: $fontFutura;
	font-size: 16px;
	line-height: 130%;
}

@mixin card-price-strikethrough-mobile {
	font-family: $fontFutura;
	font-size: 14px;
	line-height: 130%;
}

@mixin card-cart-block {
	font-family: $fontFutura;
	// font-weight: 400;
	font-size: 14px;
	line-height: 130%;
}

@mixin card-cart-block-mobile {
	font-family: $fontFuturaBook;
	font-size: 14px;
	line-height: 130%;
}

@mixin content-book {
	font-family: $fontFuturaBook;
	// font-weight: 400;
	font-size: 20px;
	line-height: 130%;
}

@mixin content-book-mobile {
	font-family: $fontFuturaBook;
	font-size: 18px;
	line-height: 130%;
}

@mixin content-book-underline {
	font-family: $fontFuturaBook;
	// font-weight: 400;
	font-size: 20px;
	line-height: 130%;
}

@mixin content-book-underline-mobile {
	font-family: $fontFuturaBook;
	// font-weight: 400;
	font-size: 20px;
	line-height: 130%;
}

@mixin header-menu {
	font-family: $fontFutura;
	// font-weight: 450;
	font-size: 18px;
	line-height: 130%;
}

@mixin button-medium {
	font-family: $fontFutura;
	// font-weight: 450;
	font-size: 18px;
	line-height: 120%;
}

@mixin footer-menu {
	font-family: $fontFutura;
	// font-weight: 450;
	font-size: 20px;
	line-height: 130%;
}

@mixin footer-menu-2 {
	font-family: $fontFuturaBook;
	// font-weight: 450;
	font-size: 16px;
	line-height: 130%;
}

@mixin content-medium {
	font-family: $fontFutura;
	// font-weight: 450;
	font-size: 14px;
	line-height: 130%;
}

@mixin button-big {
	font-family: $fontFuturaBook;
	// font-weight: 450;
	font-size: 14px;
	line-height: 130%;
}

@mixin content-book-big {
	font-family: $fontFutura;
	// font-weight: 450;
	font-size: 16px;
	line-height: 140%;
}

@mixin content-book-18 {
	font-family: $fontFuturaBook;
	// font-weight: 400;
	font-size: 18px;
	line-height: 140%;
}

@mixin content-book-18-mobile {
	font-family: $fontFuturaBook;
	font-size: 18px;
	line-height: 130%;
}
