@import '@/assets/styles/var.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/fonts.scss';

.text {
	width: 100%;
	color: $black-2;
}

.size-large {
	font-size: 20px;
	line-height: 130%;

	@include maxWidth($mTablet + 1) {
		font-size: 18px;
		line-height: 130%;
	}
}

.size-medium {
	font-size: 18px;
}

.size-small {
	font-size: 16px;
}

.size-x-small {
	font-size: 14px;
}

.center {
	text-align: center;
}

.left {
	text-align: left;
}

.right {
	text-align: right;
}

.mb-large {
	margin-bottom: 48px;
}

.mb-medium {
	margin-bottom: 32px;
}

.mb-small {
	margin-bottom: 24px;
}

.mb-x-small {
	margin-bottom: 16px;
}

.mb-xx-small {
	margin-bottom: 12px;
}

.mb-xxx-small {
	margin-bottom: 8px;
}

.mb-none {
	margin-bottom: 0;
}

.underline {
	text-decoration: underline;
}

.line-through {
	text-decoration: line-through;
}
